import { HttpRequest } from "@/services/api/http";

export default new (class TagsApi extends HttpRequest {
  constructor() {
    super();
  }

  public async updateTag(tagId: string, data: any) {
    return this.axiosClient.put(`/tags/${tagId}`, data);
  }

  public async getRefreshStatus() {
    return this.axiosClient.get(`/tags/refresh_status/`);
  }

  public async getTagBeaconList() {
    return this.axiosClient.get(`/tags/tag_beacon_list/`);
  }

  public async getTagHistory(tagId: number) {
    return this.axiosClient.get(`/tags/tag_beacon_history/${tagId}`);
  }

  public async searchTags(label: string, page: number, pageSize: number) {
    return this.axiosClient.get(
      `/tags/search/?label=${label}&page=${page}&page_size=${pageSize}`
    );
  }

  public async getCSVinformation(
    tagId: number,
    startTime: number,
    endTime: number
  ) {
    return this.axiosClient.get(`/tags/csv/${tagId}/${startTime}/${endTime}`);
  }

  public async getAnchorTagsList(time: number) {
    if (time) return this.axiosClient.get(`/tags/tag_list/?time=${time}`);
    else return this.axiosClient.get(`/tags/tag_list/`);
  }
})();
