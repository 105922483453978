<template>
  <div class="login">
    <div id="particles-js" style="width: 100%; height: 100%;"></div>
    <el-form
      label-position="top"
      :model="passwordForm"
      status-icon
      :rules="rules"
      ref="passwordForm"
      label-width="120px"
      class="login__form"
      hide-required-asterisk
      :disabled="user.loading"
    >
      <img class="login__logo" src="@/assets/logo.svg" alt="Azitek" />

      <h3>Update your password</h3>

      <el-form-item label="Email" prop="email">
        <el-input
          type="text"
          v-model="passwordForm.email"
          autocomplete="off"
          :disabled="invalidToken"
          @keyup.enter.native="submitForm('passwordForm')"
          @input="onInputChange()"
        ></el-input>
      </el-form-item>
      <el-form-item label="New Password" prop="newPassword">
        <el-input
          :type="revealNewPassword ? 'text' : 'password'"
          v-model="passwordForm.newPassword"
          autocomplete="off"
          :disabled="invalidToken"
        >
          <el-button
            tabindex="-1"
            @click="revealNewPassword = !revealNewPassword"
            slot="append"
            icon="el-icon-view"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="Confirm New Password" prop="confirmNewPassword">
        <el-input
          :type="revealConfirmPassword ? 'text' : 'password'"
          v-model="passwordForm.confirmNewPassword"
          autocomplete="off"
          :disabled="invalidToken"
        >
          <el-button
            tabindex="-1"
            @click="revealConfirmPassword = !revealConfirmPassword"
            slot="append"
            icon="el-icon-view"
          ></el-button>
        </el-input>
        <div
          v-if="passwordForm.error.length"
          class="el-form-item__error el-form-item__error--server"
        >
          {{ passwordForm.error }}
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('passwordForm')"
          :loading="user.loading"
          :disabled="invalidToken"
        >
          UPDATE PASSWORD
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import 'particles.js/particles';
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import authApi from "@/modules/auth/api/auth.api";
import userApi from "@/modules/user/api/user.api";
import { ROUTE_DASHBOARD, ROUTE_USER, ROUTE_BEACON_LIVE_DATA, ROUTE_CARGO_CHECK, ROUTE_ASSET_MANAGER, ROUTE_USER_MAIN_TAB_STATIONARY } from "@/router/router.types";

export default {
  data() {
    const validateNewPassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please enter your new password'));
        }
        callback()
      };
      const validateNewPasswordConfirmation = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please confirm your new password'));
        } else if (value !== this.passwordForm.newPassword) {
          callback(new Error('Passwords don\'t match.'));
        } else {
          callback();
        }
      };

    return {
      revealCurrentPassword: false,
      revealNewPassword: false,
      revealConfirmPassword: false,
      passwordDialogVisible: true,
      passwordForm: {
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        error: ''
      },
      invalidToken: false,
      rules: {
        currentPassword: [
          {
            required: true,
            message: "Please enter your current password",
            trigger: "blur"
          }
        ],
        newPassword: [
          {
            min: 8,
            max: 64,
            message: "Password length should be at least 8 characters",
            trigger: "blur"
          },
          { validator: validateNewPassword, trigger: 'blur' }
        ],
        confirmNewPassword: [
          {
            min: 8,
            max: 64,
            message: "Password length should be at least 8 characters",
            trigger: "blur"
          },
          { validator: validateNewPasswordConfirmation, trigger: 'blur' }
        ]
      }
    };
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapGetters("auth", ["isChangePasswordUser"]),
    ...mapGetters("clients", ["isClientDefault"])
  },

  watch: {
    "user.data.email"(newValue) {
      if (newValue && newValue.length > 0) {
        if (!this.isChangePasswordUser && this.user.data.clientType == 0)
          this.$router.replace({ name: ROUTE_DASHBOARD });
        else if (this.user.data.clientType == 1)
          this.$router.replace({ name: ROUTE_BEACON_LIVE_DATA });
        else if (this.user.data.clientType == 4)
          this.$router.replace({ name: ROUTE_ASSET_MANAGER });
        else if (this.user.data.clientType == 3)
          this.$router.replace({ name: ROUTE_CARGO_CHECK });
        else
          this.$router.replace({ name: ROUTE_USER });
      }
    },
    "user.error": {
      deep: true,
      handler: function(newValue) {
        if (newValue != null) {
          if (newValue.status === 401) {
            this.passwordForm.error = 'Unauthorized Access.'
          } else {
            this.passwordForm.error = 'Something went wrong. Try again later.'
          }
        }
      }
    }
  },

  methods: {
     ...mapActions("auth", {
      authToken: actionTypes.AUTH_LOGIN
    }),

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            await authApi.resetPassword(this.passwordForm.email, this.passwordForm.newPassword, this.$route.params.token)
            this.$notify({
              title: 'Success',
              message: 'Password changed successfully',
              type: 'success'
            });
            this.dismissDialog()
            if (this.isChangePasswordUser)
              this.logout()
          } catch (err) {
            this.passwordForm.error = err.response.data.detail
          }
        }
      });
    },

    onInputChange () {
      if (this.passwordForm.error) {
        this.passwordForm.error = ''
      }
    },
  },

  async mounted () {
    const res = await userApi.checkUserTokens(this.$route.params.token)
    if (!res.data) {
      this.invalidToken = true
      this.passwordForm.error = 'Invalid Token'
    }

    window.particlesJS('particles-js', {
      particles: {
        number: {
          value: 200,
          density: {
            enable: true,
            'value_area': 1400
          }
        },
        size: {
          value: 0.5
        },
        move: {
          speed: 0.75
        }
      },
      interactivity: {
        events: {
          onclick: {
            enable: true,
            mode: 'push'
          }
        }
      }
    });
  }
};
</script>

<style lang="scss">
.login {
  width: inherit;
  height: inherit;
  position: relative;
  min-width: inherit;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  .default-config {
    float: left;
    font-size: 12px;
    margin-top: -22px;
    margin-bottom: -22px;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  #particles-js {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
      circle,
      rgb(101, 141, 165) 0%,
      rgb(27, 60, 80) 100%
    );
    z-index: -1;
  }

  &__logo {
    margin-bottom: 40px;
    max-width: 130px;
  }

  h3 {
    position: relative;
    margin-bottom: 30px;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: radial-gradient(circle at 50% 0, #e5eaec, #fff);
    }
  }

  &__form {
    flex: 0 0 250px;
    background: white;
    padding: 24px;
    border-radius: 8px;

    @media (min-width: $xs) {
      flex: 0 0 320px;
    }

    .el-form-item label {
      display: block;
      padding: 0;
      padding-left: 2px;
      line-height: 20px;

      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
    }

    .el-button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .el-form .el-form-item .el-input-group__append i {
    position: relative;
    left: -5px;
  }
}
</style>
