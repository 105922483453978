<template>
  <div class="analytics-globals">
    <Dashboard
      :show-anchors="false"
      :interval-update="false"
      :average-time-heatmap-points="averageTimeHeatmapPoints"
      :average-time-max-value="averageTimeMaxValue"
      :frequency-heatmap-points="frequencyHeatmapPoints"
      :frequency-max-value="frequencyMaxValue"
      :trip-frequency="tripFrequency"
      :line-graph-filters="lineGraphFilters"
      @tab-switch="onTabSwitch"
      @update-tags-to-render="updateTagsToRender"
      @update-orders="updateOrders"
      @update-show-stats="onUpdateShowStats"
    >
      <div class="sidebar">
        <div class="analytics-globals__data" v-if="showStats">
          <div class="page-title">
            Activity Analytics
          </div>
          <div class="container">
            <div class="filter-container-flex">
              <div class="filter-container">
                <div class="filter-container__form">
                  <el-form label-position="left">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Define the date interval for which the routes have been concluded."
                      placement="right"
                    >
                      <el-form-item label="Date">
                        <el-date-picker
                          ref="picker"
                          v-model="dateSelected"
                          type="daterange"
                          style="width: 250px"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                          @change="onDateSelectedChange"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Define the work shift hour interval for each day selected above."
                      placement="right"
                    >
                      <el-form-item label="Work Shift Interval">
                        <div class="time-picker-wrappers">
                          <el-time-picker
                            v-model="startHour"
                            placeholder="Start time"
                            :picker-options="{
                              format: 'HH:mm'
                            }"
                            style="width: 125px"
                            format="HH:mm"
                            value-format="HH:mm"
                          >
                          </el-time-picker>
                          <el-time-picker
                            v-model="endHour"
                            placeholder="End time"
                            :picker-options="{
                              format: 'HH:mm'
                            }"
                            style="width: 125px"
                            format="HH:mm"
                            value-format="HH:mm"
                          >
                          </el-time-picker>
                        </div>
                      </el-form-item>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Select a specific lap to filter results. This field is optional."
                      placement="right"
                    >
                      <el-form-item label="Lap ID">
                        <el-input
                          placeholder="Please input"
                          v-model="routeToFilter"
                          @input="resetRouteId()"
                        ></el-input>
                      </el-form-item>
                    </el-tooltip>
                    <div class="date-buttons">
                      <el-button size="mini" @click="setDefaultDate"
                        >Default Date</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="submit = true"
                        >Submit</el-button
                      >
                    </div>
                  </el-form>
                </div>

                <div>
                  <div
                    v-if="positionLogs.loading"
                    v-loading.lock="true"
                    class="charts-loading"
                  />
                  <template v-else>
                    <div class="route-information">
                      <b>Highest Average Time </b>
                      {{
                        transformDuration(avgMax.value) + " (" + avgMax.cp + ")"
                      }}
                    </div>
                    <div class="route-information">
                      <b>Highest Frequency </b>
                      {{ countMax.value + " (" + countMax.cp + ")" }}
                    </div>
                    <div class="route-information">
                      <b>Highest Total Time </b>
                      {{
                        transformDuration(sumMax.value) + " (" + sumMax.cp + ")"
                      }}
                    </div>
                    <div class="route-information">
                      <b> Downloads </b>
                      <div>
                        <el-button
                          type="success"
                          size="mini"
                          @click="downloadCSV(false)"
                          >Positions</el-button
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="route-main-info" v-if="routeId">
              <div class="route-main-info__details">
                <span><b>Started at: </b>{{ routeInfo.startDate }}</span>
                <span><b>Ended at: </b>{{ routeInfo.endDate }}</span>
                <span><b>Duration: </b>{{ routeInfo.duration }}</span>
                <span>
                  <b>Error: </b>
                  {{
                    routeInfo.error === 1
                      ? "Timeout"
                      : routeInfo.error === 2 ||
                        routeInfo.error === 3 ||
                        routeInfo.error === 5
                      ? "Wrong Path"
                      : routeInfo.error === 4
                      ? "Offline Gateway"
                      : routeInfo.error === 6
                      ? "Route Interrupted"
                      : routeInfo.error === 7
                      ? "Min. duration not reached"
                      : routeInfo.error === 7
                      ? "Unknown Error"
                      : "N/A"
                  }}
                </span>
              </div>
            </div>

            <div
              v-if="positionLogs.loading"
              v-loading.lock="true"
              class="charts-loading"
            />
            <template v-else-if="!charts.avg.data.length">
              <h4
                class="no-data"
                v-if="
                  (!routeToFilter && !routeHasBeenSubmitted) ||
                    !routeHasBeenSubmitted
                "
              >
                No data for this time period.
              </h4>
              <h4 class="no-data" v-else-if="routeHasBeenSubmitted">
                No data for this specific route instance.
              </h4>
            </template>
            <template v-else>
              <div>
                <el-tabs v-model="activeTab">
                  <el-tab-pane name="checkpoints" label="Checkpoints">
                    <div class="analytics-globals__chart-wrapper">
                      <h2>Average time spent on checkpoint (Heatmap)</h2>
                      <bar-chart
                        :data="charts.avg.data"
                        :labels="charts.avg.labels"
                        :tagLabels="charts.avg.tagLabels"
                        x-label="Checkpoint"
                      />
                    </div>

                    <div class="analytics-globals__chart-wrapper">
                      <h2>Frequency on checkpoint (Heatmap)</h2>
                      <bar-chart
                        :data="charts.count.data"
                        :labels="charts.count.labels"
                        :tagLabels="charts.count.tagLabels"
                        y-label="Count"
                        x-label="Checkpoint"
                      />
                    </div>

                    <div class="analytics-globals__chart-wrapper">
                      <h2>Total time on checkpoint</h2>
                      <bar-chart
                        :data="charts.sum.data"
                        :labels="charts.sum.labels"
                        :tagLabels="charts.sum.tagLabels"
                        x-label="Checkpoint"
                      />
                    </div>
                  </el-tab-pane>
                  <el-tab-pane name="segments" label="Segments">
                    <div
                      v-if="loadingTripFrequency"
                      v-loading.lock="true"
                      class="trip-frequency-loading"
                    />
                    <div v-else>
                      <el-form class="line-stats-form" label-position="left">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Define the minimum and maximum of the Frequency values to show on the Filter."
                          placement="right"
                        >
                          <el-form-item label="Frequency Filter">
                            <el-input-number
                              v-model="lineGraphFilters.minFreq"
                              size="mini"
                              :min="0"
                              :max="max"
                            ></el-input-number>
                            <el-input-number
                              v-model="lineGraphFilters.maxFreq"
                              size="mini"
                              :min="0"
                              :max="max"
                            ></el-input-number>
                          </el-form-item>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Define the minimum and maximum of the Average Time values to show on the Filter."
                          placement="right"
                        >
                          <el-form-item label="Average Time Filter">
                            <el-input-number
                              v-model="lineGraphFilters.minAvgTime"
                              size="mini"
                              :min="0"
                              :max="1440"
                            ></el-input-number>
                            <el-input-number
                              v-model="lineGraphFilters.maxAvgTime"
                              size="mini"
                              :min="0"
                              :max="1440"
                            ></el-input-number>
                          </el-form-item>
                        </el-tooltip>
                      </el-form>

                      <div class="analytics-globals__chart-wrapper">
                        <h2>Average time travelling (Spaghetti Diagram)</h2>
                        <bar-chart
                          :data="filteredTimeArray"
                          :labels="filteredLineGraphLabels"
                          x-label="Checkpoint"
                        />
                      </div>

                      <div class="analytics-globals__chart-wrapper">
                        <h2>Frequency (Spaghetti Diagram)</h2>
                        <bar-chart
                          :data="filteredCountsArray"
                          :labels="filteredLineGraphLabels"
                          y-label="Count"
                          x-label="Checkpoint"
                        />
                      </div>

                      <div class="analytics-globals__chart-wrapper">
                        <h2>Total time travelling</h2>
                        <bar-chart
                          :data="filteredTotalTimeArray"
                          :labels="filteredLineGraphLabels"
                          x-label="Checkpoint"
                        />
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div class="analytics-globals__positions-table">
                <h2>Position Logs</h2>
                <el-table
                  :data="listOfPositions"
                  class="analytics-globals__positions-table__table"
                  border
                  max-height="900"
                >
                  <el-table-column
                    label="Map#"
                    :resizable="false"
                    width="70px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.renderIdentifier">
                        {{ scope.row.renderIdentifier }}
                      </span>
                      <span v-else>
                        <hr />
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Label" :resizable="false">
                    <template slot-scope="scope" v-if="scope.row.label != ''">
                      {{
                        scope.row.label.length > 25
                          ? scope.row.label.slice(0, 25).concat("...")
                          : scope.row.label
                      }}
                    </template>
                    <template v-else>
                      {{ scope.row.tagId.toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Arrival Time"
                    :resizable="false"
                    width="170px"
                  >
                    <template slot-scope="scope">
                      {{
                        moment
                          .tz(scope.row.enterTs * 1000, clientData.timezone)
                          .format("MMM DD YYYY, HH:mm:ss")
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Duration"
                    :resizable="false"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      {{
                        transformDuration(
                          scope.row.lastSeenAt - scope.row.enterTs
                        )
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Lap ID"
                    :resizable="false"
                    width="90px"
                  >
                    <template slot-scope="scope">
                      <span v-bind:style="{ color: computedColor(scope.row) }">
                        {{
                          scope.row.flag.includes(1)
                            ? scope.row.lapId
                            : scope.row.routeInstanceId
                        }}
                      </span>
                      <span>
                        <el-tooltip
                          v-if="scope.row.error.includes(1)"
                          class="item"
                          effect="dark"
                          content="Route error due to timeout."
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="
                            scope.row.error.includes(2) ||
                              scope.row.error.includes(3) ||
                              scope.row.error.includes(5)
                          "
                          class="item"
                          effect="dark"
                          content="Route error due to wrong path."
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.error.includes(4)"
                          class="item"
                          effect="dark"
                          content="Route error due to the gateway being offline."
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.error.includes(6)"
                          class="item"
                          effect="dark"
                          content="Route interrupted."
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="scope.row.error.includes(7)"
                          class="item"
                          effect="dark"
                          content="Route minimum duration was not reached."
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="isErrorGreaterThanSeven(scope.row.error)"
                          class="item"
                          effect="dark"
                          content="Unknown Error"
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                      </span>
                      <span v-if="!scope.row.routeInstanceId">
                        <hr />
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Ord"
                    :resizable="false"
                    width="80px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.ord">
                        <span
                          v-bind:style="{ color: computedOrdColor(scope.row) }"
                        >
                          {{ scope.row.ord }}
                        </span>
                        <el-tooltip
                          v-if="
                            (scope.row.mismatch != 0 &&
                              scope.row.mismatch != null &&
                              scope.row.mismatch != '0, 0') ||
                              (scope.row.skips != 0 &&
                                scope.row.skips != null &&
                                scope.row.skips != '0, 0')
                          "
                          class="item"
                          effect="dark"
                          content="Route tolerance error has been applied."
                          placement="right"
                        >
                          <i v-if="scope.row.error" class="el-icon-warning"></i>
                        </el-tooltip>
                      </span>
                      <span v-else>
                        <hr />
                      </span>
                    </template>
                  </el-table-column>
                  <!--<el-table-column label="Valid Lap Position" :resizable="false">
                  <template>
                    TBD
                  </template>
                </el-table-column>-->
                </el-table>
                <div v-if="listOfPositions.length > 20">
                  <el-button
                    @click="loadMore()"
                    :disabled="pageSize > listOfPositions.length"
                    >Load More</el-button
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </Dashboard>
  </div>
</template>

<script>
import moment from "moment-timezone";
import routesApi from "@/modules/routes/api/routes.api";
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import Dashboard from "@/views/Dashboard";
import positionLogsApi from "@/modules/position-logs/api/position-logs.api";
import positionsApi from "@/modules/position-logs/api/position-logs.api";
import barChart from "@/views/stats/components/bar-chart";
import { formatTimeUnit } from "@/utils/utils";
import { computed } from "vue";

export default {
  components: {
    barChart,
    Dashboard
  },

  data() {
    return {
      moment,
      dateSelected: [],
      pickerOptions: {
        disabledDate: null,
        onPick: this.onPick,
        shortcuts: null
      },
      positionLogs: {
        loading: true,
        error: null,
        data: []
      },
      countMax: {
        value: 0,
        cp: null
      },
      sumMax: {
        value: 0,
        cp: null
      },
      avgMax: {
        value: 0,
        cp: null
      },
      pageSize: 50,
      currentPositionPage: 0,
      listOfPositions: [],
      averageTimeHeatmapPoints: [],
      frequencyHeatmapPoints: [],
      averageTimeMaxValue: 0,
      frequencyMaxValue: 0,
      startHour: moment.utc(0).format("HH:mm"),
      endHour: moment.utc(86399 * 1000).format("HH:mm"),
      charts: {
        count: {
          data: [],
          labels: [],
          tagLabels: []
        },
        sum: {
          data: [],
          labels: [],
          tagLabels: []
        },
        avg: {
          data: [],
          labels: [],
          tagLabels: []
        }
      },
      submit: false,
      showStats: true,
      tagsToRender: [],
      routeToFilter: null,
      routeId: null,
      routeHasBeenSubmitted: false,
      orders: {},
      tripFrequency: {},
      defaultGraphsActive: true,
      countsArray: [],
      filteredCountsArray: [],
      averageTimeArray: [],
      filteredTimeArray: [],
      totalTimeArray: [],
      filteredTotalTimeArray: [],
      lineGraphLabels: [],
      filteredLineGraphLabels: [],
      lineGraphFilters: {
        maxFreq: 0,
        minFreq: 0,
        maxAvgTime: 0,
        minAvgTime: 0
      },
      max: 0,
      loadingTripFrequency: false,
      activeTab: "checkpoints"
    };
  },

  computed: {
    ...mapState("clients", {
      selectedAnchor: "selectedAnchor",
      clientData: "data"
    }),

    ...mapState("user", {
      resources: "resources"
    }),

    routeInfo() {
      const routeMainInfo = this.selectedAnchor?.recentRoutes?.find(
        anchor => anchor.id == this.routeId
      );
      const routeObj = {};

      if (routeMainInfo) {
        routeObj.startDate = this.getRouteStartDate(routeMainInfo);
        routeObj.duration = this.getRouteDuration(routeMainInfo);
        routeObj.endDate = this.getRouteEndDate(
          routeObj.duration,
          routeObj.startDate
        );
        routeObj.error = routeMainInfo.error;
      }
      return routeObj;
    }
  },

  props: {
    lapId: {
      type: Number,
      default: 0
    }
  },

  watch: {
    selectedAnchor: {
      handler(newValue) {
        this.$router.push({
          name: this.$route.name,
          params: {
            anchorId: this.selectedAnchor.anchorId
          }
        });

        this.loadingTripFrequency = true;
        this.getPositionsFromAnchor();
        this.getTripFrequency();
        this.loadingTripFrequency = false;
      }
    },

    "lineGraphFilters.minFreq": {
      handler() {
        this.handleSegmentFilterChange();
      }
    },

    "lineGraphFilters.maxFreq": {
      handler() {
        this.handleSegmentFilterChange();
      }
    },

    "lineGraphFilters.minAvgTime": {
      handler() {
        this.handleSegmentFilterChange();
      }
    },

    "lineGraphFilters.maxAvgTime": {
      handler() {
        this.handleSegmentFilterChange();
      }
    },

    submit: {
      async handler(newValue) {
        if (newValue) {
          this.submit = false;
          this.loadingTripFrequency = true;
          console.log("loading...");
          this.getPositionsFromAnchor();
          this.listOfPositions = [];
          this.currentPositionPage = 0;
          const positionList = await this.fetchInformation();
          this.listOfPositions = this.listOfPositions.concat(positionList);
          this.setPositionInformationRenderIdentifier();
          this.getTripFrequency();
          this.loadingTripFrequency = false;
          console.log("loading done");

          if (this.routeToFilter) {
            this.routeHasBeenSubmitted = true;
            this.routeId = this.routeToFilter;
          } else this.routeHasBeenSubmitted = false;
        }
      }
    }
  },

  methods: {
    resetHours() {
      this.startHour = moment.utc(0).format("HH:mm");
      (this.endHour = moment.utc(86399 * 1000).format("HH:mm")),
        (this.submit = true);
    },

    resetRouteId() {
      this.routeId = null;
    },

    // Parses the string duration to seconds
    parseDurationToSeconds(duration) {
      const timeParts = duration.match(/(\d+h)?(\d+m)?(\d+s)?/);
      let totalSeconds = 0;

      if (timeParts[1]) {
        totalSeconds += parseInt(timeParts[1].replace("h", "")) * 3600;
      }
      if (timeParts[2]) {
        totalSeconds += parseInt(timeParts[2].replace("m", "")) * 60;
      }
      if (timeParts[3]) {
        totalSeconds += parseInt(timeParts[3].replace("s", ""));
      }

      return totalSeconds;
    },

    // Calculates and formats the duration of a route based on the route object
    getRouteStartDate(routeMainInfo) {
      const startDate = routeMainInfo.startTime;

      return (
        moment
          .tz(moment.unix(startDate), this.clientData.timezone)
          .format("MMM DD YYYY, ") +
        formatTimeUnit(startDate, true, this.clientData)
      );
    },

    // Calculates and formats the duration of a route based on the route object
    getRouteDuration(routeMainInfo) {
      return formatTimeUnit(routeMainInfo.duration, false, this.clientData);
    },

    // Gets the end date of a route based on start date and duration
    getRouteEndDate(routeDuration, routeStartDate) {
      let startMoment;
      const durationSeconds = this.parseDurationToSeconds(routeDuration);

      if (/^\w{3} \d{2} \d{4}, \d{2}m\d{2}s$/.test(routeStartDate)) {
        startMoment = moment.tz(
          routeStartDate,
          "MMM DD YYYY, mmss",
          this.clientData.timezone
        );
      } else {
        startMoment = moment.tz(
          routeStartDate,
          "MMM DD YYYY, HHmm",
          this.clientData.timezone
        );
      }

      const endMoment = startMoment.clone().add(durationSeconds, "seconds");
      const endUnixTime = endMoment.unix();

      return (
        endMoment.format("MMM DD YYYY, ") +
        formatTimeUnit(endUnixTime, true, this.clientData)
      );
    },

    handleSegmentFilterChange() {
      // Parse the duration string using Moment.js
      const parsedMinTime = this.lineGraphFilters.minAvgTime * 60; //convert to seconds
      const parsedMaxTime = this.lineGraphFilters.maxAvgTime * 60; //convert to seconds
      const indexArray = [];

      this.filteredTimeArray = this.averageTimeArray.filter((elem, idx) => {
        if (
          elem <= parsedMaxTime &&
          elem >= parsedMinTime &&
          this.countsArray[idx] <= this.lineGraphFilters.maxFreq &&
          this.countsArray[idx] >= this.lineGraphFilters.minFreq
        ) {
          return true;
        }
      });

      this.filteredCountsArray = this.countsArray.filter((elem, idx) => {
        if (
          elem <= this.lineGraphFilters.maxFreq &&
          elem >= this.lineGraphFilters.minFreq &&
          this.averageTimeArray[idx] <= parsedMaxTime &&
          this.averageTimeArray[idx] >= parsedMinTime
        ) {
          indexArray.push(idx);
          return true;
        }
      });

      this.filteredTotalTimeArray = this.totalTimeArray.filter((elem, idx) =>
        indexArray.includes(idx)
      );

      this.filteredLineGraphLabels = this.lineGraphLabels.filter((elem, idx) =>
        indexArray.includes(idx)
      );
    },

    onTabSwitch(tab) {
      this.activeTab = tab;
    },

    computedColor(position) {
      if (
        position.error != 0 &&
        position.error != null &&
        position.error != "0, 0"
      )
        return "red";
      else return "#606266";
    },

    computedOrdColor(position) {
      if (
        (position.mismatch != 0 &&
          position.mismatch != null &&
          position.mismatch != "0, 0") ||
        (position.skips != 0 &&
          position.skips != null &&
          position.skips != "0, 0")
      ) {
        return "red";
      } else {
        return "#606266";
      }
    },

    async getTripFrequency() {
      try {
        const startDateUnix = moment
          .utc(moment.tz(this.dateSelected[0], this.clientData.timezone))
          .unix();
        console.log(this.clientData.timezone);
        const endDateUnix = moment
          .utc(moment.tz(this.dateSelected[1], this.clientData.timezone))
          .unix();
        const endSeconds = 86399; //seconds in day

        this.loadingTripFrequency = true;
        const res = await positionsApi.getTripFrequency(
          this.selectedAnchor.anchorId,
          startDateUnix,
          endDateUnix + endSeconds,
          this.routeToFilter !== 0 ? this.routeToFilter : null
        );
        this.tripFrequency = res.data;

        this.countsArray = [];
        this.totalTimeArray = [];
        this.lineGraphLabels = [];
        let maxFreq = 0;
        const objToSort = {};

        // Iterate over the keys of the dictionary
        for (const key in this.tripFrequency) {
          // Iterate over the array of objects for each key
          this.tripFrequency[key].forEach(entry => {
            let father;
            let child;
            this.tagsToRender.forEach(tag => {
              if (tag.id == key) father = tag.renderIdentifier;
              if (tag.id == entry.tagId) child = tag.renderIdentifier;
            });

            if (father != null && child != null) {
              objToSort[father + " - " + child] = {
                count: entry.count,
                avgTime:
                  entry.count != 0
                    ? entry.totalTime / entry.count
                    : entry.count,
                totalTime: entry.totalTime
              };
            }
          }, this);
        }

        // Custom sorting function
        const customSort = (a, b) => {
          const parseKey = key => key.split(/\s*[-|]\s*/).map(Number);
          const [aValues, bValues] = [parseKey(a), parseKey(b)];

          // Compare each pair of numeric values
          for (let i = 0; i < Math.min(aValues.length, bValues.length); i++) {
            if (aValues[i] !== bValues[i]) {
              return aValues[i] - bValues[i];
            }
          }

          // If all numeric parts are equal, compare the lengths of the keys
          return a.length - b.length;
        };

        // Convert object to array of key-value pairs, sort, and convert back to object
        const sortedData = Object.fromEntries(
          Object.entries(objToSort).sort(([keyA], [keyB]) =>
            customSort(keyA, keyB)
          )
        );

        for (const key in sortedData) {
          this.countsArray.push(sortedData[key].count);
          if (sortedData[key].count != 0)
            this.averageTimeArray.push(
              sortedData[key].totalTime / sortedData[key].count
            );
          else this.averageTimeArray.push(0);
          this.totalTimeArray.push(sortedData[key].totalTime);
          this.lineGraphLabels.push(key);
          if (sortedData[key].count > maxFreq) maxFreq = sortedData[key].count;
        }

        this.lineGraphFilters.minFreq =
          this.lapId != 0 ? 0 : Math.floor(0.05 * maxFreq);
        this.lineGraphFilters.maxFreq = maxFreq;
        this.max = maxFreq;
        this.lineGraphFilters.maxAvgTime = 60; // convert to minutes
        this.lineGraphFilters.minAvgTime = 0; // convert to minutes

        this.filteredCountsArray = cloneDeep(this.countsArray);
        this.filteredTimeArray = cloneDeep(this.averageTimeArray);
        this.filteredTotalTimeArray = cloneDeep(this.totalTimeArray);
        this.loadingTripFrequency = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
    },

    async fetchInformation() {
      const startDateUnix = moment
        .utc(moment.tz(this.dateSelected[0], this.clientData.timezone))
        .unix();
      const endDateUnix = moment
        .utc(moment.tz(this.dateSelected[1], this.clientData.timezone))
        .unix();
      let startSeconds = 0;
      let endSeconds = 0;
      if (this.startHour !== null) {
        //console.log(moment.tz(scope.row.enterTs * 1000, clientData.timezone))
        startSeconds =
          moment(this.startHour, "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 1;
        endSeconds =
          moment(this.endHour, "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 59;
      } else {
        startSeconds = 0;
        endSeconds = 86399;
      }
      const resPos = await positionsApi.getAnalyticsLogs(
        this.selectedAnchor.anchorId,
        startDateUnix,
        endDateUnix,
        startSeconds,
        endSeconds,
        this.routeToFilter !== 0 ? this.routeToFilter : null,
        this.currentPositionPage,
        this.pageSize
      );
      return resPos.data;
    },

    async onDateSelectedChange() {
      this.loadingTripFrequency = true;
      this.getPositionsFromAnchor();
      this.listOfPositions = [];
      this.currentPositionPage = 0;
      const positionList = await this.fetchInformation();
      this.listOfPositions = this.listOfPositions.concat(positionList);
      this.getTripFrequency();
      this.setPositionInformationRenderIdentifier();
      this.loadingTripFrequency = false;
    },

    setPositionInformationRenderIdentifier() {
      const _self = this; // eslint-disable-line
      this.listOfPositions.forEach(infoElement => {
        for (let i = 0; i < _self.tagsToRender.length; i++) {
          if (
            infoElement.tagId.toUpperCase() ===
            _self.tagsToRender[i].tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("")
          ) {
            infoElement.renderIdentifier =
              _self.tagsToRender[i].renderIdentifier;
            break;
          }
        }
      });
    },

    transformDuration(value) {
      if (value > 3600) {
        return (
          moment.utc(value * 1000).format("HH") +
          "h" +
          moment.utc(value * 1000).format("mm") +
          "m"
        );
      }
      return (
        moment.utc(value * 1000).format("mm") +
        "m" +
        moment.utc(value * 1000).format("ss") +
        "s"
      );
    },

    async downloadCSV(details) {
      const csvdata = [];
      let res = null;

      let startSeconds = 0;
      let endSeconds = 0;

      const startDate = moment
        .utc(moment.tz(this.dateSelected[0], this.clientData.timezone))
        .unix();
      const endDate = moment
        .utc(moment.tz(this.dateSelected[1], this.clientData.timezone))
        .unix();

      // const SECONDS_ID_DAY = 86400;
      // endDate = endDate + SECONDS_ID_DAY - 1;

      if (this.startHour !== null) {
        startSeconds =
          moment(this.startHour, "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 1;
        endSeconds =
          moment(this.endHour, "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 59;
      } else {
        startSeconds = 0;
        endSeconds = 86399;
      }

      try {
        //TROCAR ARGUMENTOS
        res = await routesApi.getPositionsCSV(
          this.selectedAnchor.anchorId,
          startDate,
          endDate,
          startSeconds,
          endSeconds,
          this.routeToFilter
        );
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(res.data);
      anchor.target = "_blank";
      if (details) anchor.download = "routes-analytics.csv";
      else anchor.download = "routes-positions.csv";
      anchor.click();
    },

    getTimeTransformed(info) {
      return moment.unix(info).format("MMM DD YYYY, HH:mm:ss");
    },

    async loadMore() {
      this.currentPositionPage += 1;
      const positionList = await this.fetchInformation();
      this.listOfPositions = this.listOfPositions.concat(positionList);
      this.setPositionInformationRenderIdentifier();
    },

    async getPositionsFromAnchor() {
      const startDateUnix = moment
        .utc(moment.tz(this.dateSelected[0], this.clientData.timezone))
        .unix();
      const endDateUnix = moment
        .utc(moment.tz(this.dateSelected[1], this.clientData.timezone))
        .unix();
      //const SECONDS_IN_DAY = 86400;
      //endDateUnix = endDateUnix + SECONDS_IN_DAY - 1;
      let startSeconds = 0;
      let endSeconds = 0;

      this.positionLogs.loading = true;

      try {
        let res = null;
        if (this.startHour !== null) {
          //console.log(moment.tz(scope.row.enterTs * 1000, clientData.timezone))
          startSeconds =
            moment(this.startHour, "HH:mm").diff(
              moment().startOf("day"),
              "seconds"
            ) + 1;
          endSeconds =
            moment(this.endHour, "HH:mm").diff(
              moment().startOf("day"),
              "seconds"
            ) + 59;
        } else {
          startSeconds = 0;
          endSeconds = 86399;
        }

        res = await positionLogsApi.getAnchorPositionLogsFromTo(
          this.selectedAnchor.anchorId,
          startDateUnix,
          endDateUnix,
          startSeconds,
          endSeconds,
          this.routeToFilter !== 0 ? this.routeToFilter : null
        );
        this.positionLogs.data = res.data;
      } catch (err) {
        this.positionLogs.error = err;
      } finally {
        this.createChartData();
        this.positionLogs.loading = false;
      }
    },

    resetChartData() {
      this.charts.count = {
        data: [],
        labels: [],
        tagLabels: []
      };
      this.charts.sum = {
        data: [],
        labels: [],
        tagLabels: []
      };
      this.charts.avg = {
        data: [],
        labels: [],
        tagLabels: []
      };
    },

    createChartData() {
      this.resetChartData();
      this.averageTimeHeatmapPoints = [];
      this.frequencyHeatmapPoints = [];
      const countMax = {
        value: 0,
        cp: 0
      };
      const sumMax = {
        value: 0,
        cp: 0
      };
      const avgMax = {
        value: 0,
        cp: 0
      };
      // this.resources.data.tags = this.resources.data.tags.filter(
      //   tag => tag.lastPosition
      // );
      // let biggestOrd = 0;
      // this.orders = {};
      // if (this.selectedAnchor.activeRoute) {
      //   this.selectedAnchor.activeRoute.tags.forEach(tag => {
      //     if (this.orders[tag.tagPositionId])
      //       this.orders[tag.tagPositionId] = this.orders[tag.tagPositionId] +=
      //         " | " + tag.ord.toString();
      //     else this.orders[tag.tagPositionId] = tag.ord.toString();
      //     if (tag.ord > biggestOrd) biggestOrd = tag.ord;
      //   });
      // }

      // this.resources.data.tags.sort(function(a, b) {
      //   if (b.lastPosition.y == a.lastPosition.y)
      //     return a.lastPosition.x - b.lastPosition.x;
      //   return b.lastPosition.y - a.lastPosition.y;
      // });
      // this.tagsToRender = cloneDeep(this.resources.data.tags);

      // this.tagsToRender.forEach(tag => {
      //   if (tag.parentId == null) {
      //     tag.renderIdentifier = this.orders[tag.lastPosition.id];
      //   }
      // });
      // this.tagsToRender.forEach(tag => {
      //   if (tag.parentId == null && !this.orders[tag.lastPosition.id]) {
      //     tag.renderIdentifier = (biggestOrd + 1).toString();
      //     biggestOrd += 1;
      //   }
      // });
      const _self = this; // eslint-disable-line

      this.positionLogs.data.sort(function(a, b) {
        const tagIndex = _self.tagsToRender.findIndex(
          tag => tag.id === a.tagId
        );
        const tagIndex2 = _self.tagsToRender.findIndex(
          tag => tag.id === b.tagId
        );
        if (tagIndex != -1 && tagIndex2 != -1) {
          if (
            _self.tagsToRender[tagIndex].renderIdentifier &&
            _self.tagsToRender[tagIndex2].renderIdentifier
          ) {
            return (
              parseInt(
                _self.tagsToRender[tagIndex].renderIdentifier
                  .toString()
                  .split(" | ")[0]
              ) -
              parseInt(
                _self.tagsToRender[tagIndex2].renderIdentifier
                  .toString()
                  .split(" | ")[0]
              )
            );
          }
        }
      });

      this.positionLogs.data.forEach(entry => {
        let alreadyAdded = false;
        const tagIndex = this.resources.data.tags.findIndex(
          tag => tag.id === entry.tagId
        );

        if (
          tagIndex >= 0 &&
          this.tagsToRender[tagIndex].active &&
          this.tagsToRender[tagIndex].renderIdentifier
        ) {
          entry.sum += 1;
          if (entry.count != 0 && entry.sum != 0) {
            this.charts.count.data.push(entry.count);
            this.charts.count.labels.push(
              this.tagsToRender[tagIndex].renderIdentifier
            );
            this.charts.count.tagLabels.push(this.tagsToRender[tagIndex].label);
            if (entry.count > countMax.value) {
              countMax.value = entry.count;
              countMax.cp = this.tagsToRender[tagIndex].renderIdentifier;
            }

            this.charts.sum.data.push(entry.sum);
            this.charts.sum.labels.push(
              this.tagsToRender[tagIndex].renderIdentifier
            );
            this.charts.sum.tagLabels.push(this.tagsToRender[tagIndex].label);
            if (entry.sum > sumMax.value) {
              sumMax.value = entry.sum;
              sumMax.cp = this.tagsToRender[tagIndex].renderIdentifier;
            }

            this.charts.avg.data.push(entry.avg);
            this.charts.avg.labels.push(
              this.tagsToRender[tagIndex].renderIdentifier
            );
            this.charts.avg.tagLabels.push(this.tagsToRender[tagIndex].label);
            if (entry.avg > avgMax.value) {
              avgMax.value = entry.avg;
              avgMax.cp = this.tagsToRender[tagIndex].renderIdentifier;
            }

            //Criar uma lista auxiliar em se um beacon aparecer mais que uma vez, os seus valores sao somados e é este o valor usado.

            this.averageTimeHeatmapPoints.forEach(point => {
              if (point[1] === tagIndex) {
                point[0] += entry.avg;
                alreadyAdded = true;
                if (point[0] > this.averageTimeMaxValue)
                  this.averageTimeMaxValue = entry.avg;
              }
            });

            this.frequencyHeatmapPoints.forEach(point => {
              if (point[1] === tagIndex) {
                point[0] += entry.avg;
                alreadyAdded = true;
                if (point[0] > this.averageTimeMaxValue)
                  this.averageTimeMaxValue = entry.avg;
              }
            });

            if (!alreadyAdded) {
              this.averageTimeHeatmapPoints.push([entry.avg, tagIndex]);
              if (entry.avg > this.averageTimeMaxValue)
                this.averageTimeMaxValue = entry.avg;

              this.frequencyHeatmapPoints.push([entry.count, tagIndex]);
              if (entry.count > this.frequencyMaxValue)
                this.frequencyMaxValue = entry.count;
            }
          }
        }
        this.countMax = countMax;
        this.sumMax = sumMax;
        this.avgMax = avgMax;
      });
    },
    setDefaultDate() {
      const endDate = moment();
      const ONE_WEEK_SINCE_END_DATE = endDate
        .clone()
        .subtract(7, "days")
        .startOf("day");
      const startDate = ONE_WEEK_SINCE_END_DATE;

      this.dateSelected = [
        startDate.format("yyyy-MM-DD"),
        endDate.format("yyyy-MM-DD")
      ];
      this.startHour = moment.utc(0).format("HH:mm");
      this.endHour = moment.utc(86399 * 1000).format("HH:mm");
      this.routeToFilter = this.$route.params.lapId
        ? this.$route.params.lapId
        : null;
      this.routeId = this.routeToFilter;
      this.submit = true;
    },
    isErrorGreaterThanSeven(error) {
      const errorValues = error.split(",").map(val => Number(val.trim()));
      return errorValues.some(val => !isNaN(val) && val > 7);
    },
    updateTagsToRender(value) {
      this.tagsToRender = value;
    },
    updateOrders(value) {
      this.orders = value;
    },
    onUpdateShowStats(booleanValue) {
      this.showStats = booleanValue;
    }
  },

  async created() {
    this.setDefaultDate();
    const positionList = await this.fetchInformation();
    console.log(this.$route.name);
  },
  mounted() {
    this.getPositionsFromAnchor();
    this.createChartData();
  }
};
</script>

<style lang="scss">
.analytics-globals {
  // display: grid;
  // grid-template-columns: 50% 50%;

  .sidebar {
    display: block !important;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 60px 0 20px 38px;
    background: $--border-color-extra-light;
    width: 50vw;
    max-height: 100vh;
    overflow-y: auto;

    @media (max-width: 1900px) {
      width: 900px;
      padding-left: 5px;
      z-index: 30;
      border-left: 1px solid $--border-color-base;
    }

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  .dashboard__map-wrapper {
    height: calc(100vh - 105px);
    width: 100%;
    padding-top: 0;
    position: fixed;
  }

  .dashboard--has-slot {
    .dashboard__map-wrapper {
      width: calc(100% - 52vw);
      float: left;
      border-right: none;
      position: fixed;
    }
  }
  .dashboard__map.leaflet-container {
    height: 100%;
    border-bottom: none;
  }

  .dashboard__slot {
    position: relative;
    padding-top: 24px;
    width: 50vw;
    height: calc(100vh - 164px);
    max-height: calc(100vh - 104px);
    float: left;
    overflow: auto;

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  // #image-map {
  //   height: 100%;
  //   position: fixed;
  //   width: 48%;
  // }

  .filter-container-flex {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  .trip-frequency-loading {
    margin-top: 100px;

    .el-loading-spinner {
      top: -42px;
    }
  }

  .charts-loading {
    margin-top: 10%;
  }

  .route-information {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 40px;
    justify-content: left;
    text-align: left;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    align-items: flex-end;
  }

  @media (max-width: 1700px) {
    .route-information {
      display: flex;
      justify-content: space-between;
      margin-left: 0px;
    }
  }

  .filter-container {
    display: grid;
    grid-template-columns: 50% 40%;
    width: 860px;

    &__form {
      .el-form-item__content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;

        @media (max-width: 550px) {
          width: 100%;
          justify-content: flex-start;
        }
      }

      .el-form-item {
        margin-bottom: 2px;

        @media (max-width: 550px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .el-date-editor {
        @media (max-width: 550px) {
          width: 100% !important;
        }
      }

      .el-input {
        width: 250px;

        @media (max-width: 550px) {
          width: 100% !important;
        }
      }

      .el-form-item label.el-form-item__label {
        line-height: 40px;
        text-transform: none;
        font-weight: bold;
        font-size: 16px;
        color: $--color-primary;
      }
    }
  }

  .route-main-info {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 25px 80px 30px 0;

    @media (max-width: 1700px) {
      padding: 25px 0 30px;
    }

    &__details {
      display: flex;
      gap: 48px;
      text-align: left;
      justify-content: center;

      @media (max-width: 1700px) {
        display: grid;
        justify-content: start;
        width: 80%;
        gap: 10px;
      }
    }
  }

  .line-stats-form {
    margin-left: 3%;
    .el-form-item__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
    }

    .el-form-item {
      margin-bottom: 2px;
    }

    .el-input-number--mini {
      margin-right: 10px;
    }

    .el-date-editor {
      margin-right: 10px;
      width: 130px;
    }

    .el-form-item label.el-form-item__label {
      line-height: 40px;
      text-transform: none;
      font-weight: bold;
      font-size: 16px;
      color: $--color-primary;
      width: 170px;
    }
  }

  @media (max-width: 1700px) {
    .filter-container {
      display: grid;
      grid-template-columns: 100%;
      width: 80%;
    }
  }
  @media (max-width: 500px) {
    .filter-container {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
      top: 24px;
    }

    .dashboard__fit-to-bounds {
      top: 105px;
    }

    //   .dashboard__tag-slider {
    //     top: 138px;
    //   }

    //   .dashboard .toggle-dashboard-slot--close {
    //     top: 158px;
    //   }

    //   .dashboard__slider {
    //     top: 47px;
    //   }
  }
  @media (max-width: $md) and (min-width: 701px) {
    .dashboard__fit-to-bounds {
      top: 78px;
    }

    // .dashboard__checkbox {
    //   top: 142px;
    // }
    // .dashboard__checkbox-two {
    //   top: 100px;
    // }
    // .dashboard__checkbox-three {
    //   top: 20px;
    // }
    // .dashboard__checkbox-four {
    //   top: 60px;
    // }
    // .dashboard__tag-slider {
    //   top: 133px;
    // }
  }

  @media (max-width: 1740px) and (min-width: 501px) {
    .dashboard__checkbox {
      top: 51px;
    }
    .dashboard__checkbox-two {
      top: 92px;
    }
    .dashboard__checkbox-three {
      top: 51px;
    }
    .dashboard__checkbox-four {
      top: 92px;
    }
    .dashboard__slider {
      top: 133px;
    }
  }

  .date-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .no-data {
    margin-top: 2%;
  }

  .date-hour-pickers {
    display: grid;
    grid-template-columns: 30% 40%;
    margin-bottom: 15px;
    grid-gap: 5px;
  }

  .page-title {
    font-weight: bold;
  }

  .descriptors {
    display: flex;
    justify-content: right;
    align-self: center;
  }

  .time-pickers {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .time-picker-wrappers {
    display: flex;
    justify-content: center;
  }

  .analytics-globals__data {
    margin-top: 5%;
    padding: 12px 0;

    @media (max-width: 960px) {
      margin-top: 80px;

      .container {
        margin: 0 20px;
      }
    }
    @media (max-width: 380px) {
      margin-top: 115px;
    }
  }

  .download-csv {
    margin-top: 10px;
  }

  .position-info-admin {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 16% 16% 16% 16% 18% 18%;
    margin-left: 2%;
    grid-gap: 10px;
    color: #444;
    text-align: center;
  }

  .position-info {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    margin-left: 2%;
    grid-gap: 10px;
    color: #444;
    text-align: center;
  }

  // .dashboard__map-wrapper {
  //   position: fixed !important;
  //   width: 48%;
  //   height: 10%;
  //   border-top: 1px solid #dcdfe6;
  // }

  .analytics-globals__positions-table {
    border-top: 2px solid grey;
    padding-top: 40px;
    margin: 40px auto auto 3%;
    max-width: 850px;
    z-index: -1;

    &__table {
      margin-top: 15px;
    }
  }

  .analytics-globals__chart-wrapper {
    margin: 24px auto auto 3%;
    max-width: 800px;

    canvas {
      max-width: 800px;
      margin-top: 12px;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
      max-height: 400px;

      @media (max-width: 915px) {
        width: 95% !important;
        padding: 24px 10px;
      }
    }
  }

  .el-tabs__item {
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    font-size: 18px;
    width: 850px;

    @media (max-width: 960px) {
      width: auto;
    }
  }
}
</style>
