export const ROUTE_LOGIN = "login";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_ROUTES = "routes";
export const ROUTE_STATS = "stats";
export const ROUTE_ANALYTICS_POSITIONS = "positions";
export const ROUTE_ANALYTICS_POSITIONS_DETAILS = "positions-details";
export const ROUTE_STATS_ROUTE = "stats-route";
export const ROUTE_STATS_GLOBALS = "stats-globals";
export const ROUTE_STATS_ANCHORS = "stats-anchors";
export const ROUTE_GLOBAL_MAP = "global-map";
export const ROUTE_GLOBAL_PREDICTIONS = "global-predictions";
export const ROUTE_FULLSCREEN = "fullscreen";
export const ROUTE_USER = "user";
export const ROUTE_USER_MAIN_TAB = "beacons-location";
export const ROUTE_USER_MAIN_TAB_STATIONARY = "account";
export const ROUTE_USER_ACCOUNT = "account";
export const ROUTE_USER_CHANGE_PASSWORD = "user-change-password";
export const ROUTE_FORGOT_PASSWORD = "forgot-password";
export const ROUTE_BEACON_LIVE_DATA = "beacon-live-data";
export const ROUTE_CARGO_CHECK = "cargo-check";
export const ROUTE_ASSET_MANAGER = "asset-manager";
