<template>
  <div class="stats-charts">
    <div class="container">
      <div class="page-title">
        Lap Analytics
      </div>
      <el-form label-position="left">
        <div class="filter-container-flex">
          <div class="form-select">
            <el-tooltip
              class="item"
              effect="dark"
              content="Select the Gateway that you wish to analyze."
              :hide-after="2500"
              placement="top"
              v-if="isAnchorPrimary"
            >
              <el-form-item label="Gateway" class="self-end-item">
                <el-select
                  v-model="selectedAnchorId"
                  placeholder="Select Gateway"
                  clearable
                  @change="onAnchorSelected"
                  @clear="onClearAnchor"
                >
                  <el-option
                    v-for="item in anchorList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Select the Route that you wish to analyze."
              :hide-after="2500"
              placement="top"
              v-else
            >
              <el-form-item
                label="Route"
                v-if="!isAnchorPrimary"
                class="self-end-item"
              >
                <el-select
                  v-model="selectedRouteId"
                  :placeholder="selectLoading ? '' : 'Select Route'"
                  clearable
                  v-loading="selectLoading"
                  element-loading-background="inherit"
                  ref="routesSelector"
                  @change="handleSelectedRoute"
                  @clear="onClearRoute"
                >
                  <el-option
                    v-for="route in routesToShow"
                    :key="route.id"
                    :label="route.label"
                    :value="route.id"
                  >
                    <span
                      :style="
                        `float: left; padding-right: 5px; font-weight: ${
                          route.anchors.length ? 'bold' : 'auto'
                        }; max-width: 200px; white-space: nowrap; text-overflow: ellipsis;
                        overflow: hidden;`
                      "
                    >
                      {{ route.label }}</span
                    >
                    <span style="float: left; padding-right: 5px">
                      <i
                        :class="
                          route.constraintTag
                            ? 'el-icon-location-outline'
                            : route.constraintShiftStart != 0 ||
                              route.constraintShiftEnd != 86399
                            ? 'el-icon-time'
                            : ''
                        "
                      >
                      </i>
                    </span>
                    <span
                      style="float: right; font-size: 12px; padding-left: 5px"
                      v-if="route.startDate"
                    >
                      ({{ moment.unix(route.startDate).format("MMM DD YYYY") }})
                    </span>
                    <span
                      style="float: right; font-size: 12px"
                      v-if="
                        route.constraintShiftStart != 0 ||
                          route.constraintShiftEnd != 86399
                      "
                    >
                      {{ formatTime(route.constraintShiftStart) }} -
                      {{ formatTime(route.constraintShiftEnd) }}
                    </span>
                    <span
                      style="float: right; font-size: 12px"
                      v-else-if="route.constraintTag"
                    >
                      {{ route.constraintTag }}
                    </span>
                  </el-option>
                  <el-option
                    :key="this.showToggleText"
                    label=""
                    value=""
                    :style="{ fontWeight: 'bold' }"
                    @click.native="handleToggleRoutesList"
                    v-if="
                      this.routesWithAnchors.length !== this.routeList.length
                    "
                  >
                    <span
                      style="display: flex; justify-content: center; align-items: center;"
                    >
                      {{ this.showToggleText }}
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tooltip>

            <el-button
              @click="switchIsAnchorPrimary"
              class="arrow-left-right-button"
            >
              <img src="@/assets/arrow-left-right.svg" alt="arrow-left-right" />
            </el-button>

            <el-tooltip
              class="item"
              effect="dark"
              content="Select the Route of this gateway you wish to analyze."
              v-if="isAnchorPrimary"
              :hide-after="2500"
              placement="top"
            >
              <el-form-item label="Route" id="route-select">
                <el-select
                  v-model="routeSelectedForFilter"
                  placeholder="Select Route"
                  clearable
                  @change="onRouteSelectedForFilterChange"
                  @clear="onClearRoute"
                  ref="mySelect"
                >
                  <el-option
                    v-for="item in anchorRoutesHistory"
                    :key="item.id"
                    :label="getRouteDate(item)"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tooltip>

            <el-tooltip
              class="item"
              effect="dark"
              content="Select the Gateways of this route you wish to analyze."
              placement="top"
              :hide-after="2500"
              v-else
            >
              <el-form-item label="Gateways">
                <el-select
                  v-model="selectedAnchors"
                  multiple
                  filterable
                  default-first-option
                  clearable
                  :placeholder="selectLoading ? '' : 'Select Gateways'"
                  class="selected-anchors"
                  v-if="!isAnchorPrimary"
                  @change="updateData"
                  v-loading="selectLoading"
                  element-loading-background="inherit"
                >
                  <el-option
                    v-for="anchor in routeAnchors"
                    :key="anchor.id"
                    :label="
                      anchor.label
                        ? anchor.label + getCreatedDate(anchor.id)
                        : anchor.id + getCreatedDate(anchor.id)
                    "
                    :value="anchor.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tooltip>
          </div>
          <div class="filter-container">
            <div class="date-hour-pickers">
              <el-tooltip
                class="item"
                effect="dark"
                content="Define the date interval for which the laps have been finished."
                placement="left"
                :hide-after="2500"
              >
                <el-form-item label="Date" class="picker-form-item">
                  <el-date-picker
                    ref="picker"
                    v-model="dateSelected"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    @change="changedDate = true"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="Define the work shift hour interval for each day selected above."
                placement="left"
                :hide-after="2500"
              >
                <el-form-item
                  label="Work Shift Interval"
                  class="picker-form-item"
                >
                  <div class="time-picker-wrappers">
                    <el-time-picker
                      v-model="startHour"
                      placeholder="Start time"
                      :picker-options="{
                        format: 'HH:mm'
                      }"
                      format="HH:mm"
                      value-format="HH:mm"
                      @change="changedInputData = true"
                    >
                    </el-time-picker>
                    <el-time-picker
                      v-model="endHour"
                      placeholder="End time"
                      :picker-options="{
                        format: 'HH:mm'
                      }"
                      format="HH:mm"
                      value-format="HH:mm"
                      @change="changedInputData = true"
                    >
                    </el-time-picker>
                  </div>
                </el-form-item>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="Define the min and max duration for the laps. Max duration should be 0 in order to be ignored."
                placement="left"
                :hide-after="3000"
              >
                <el-form-item
                  label="Lap Duration Filter"
                  class="picker-form-item"
                >
                  <div class="time-picker-wrappers">
                    <el-time-picker
                      v-model="startDuration"
                      placeholder="Start time"
                      :picker-options="{
                        format: 'HH:mm'
                      }"
                      format="HH:mm"
                      value-format="HH:mm"
                      @change="changedInputData = true"
                    >
                    </el-time-picker>
                    <el-time-picker
                      v-model="endDuration"
                      placeholder="End time"
                      :picker-options="{
                        format: 'HH:mm'
                      }"
                      format="HH:mm"
                      value-format="HH:mm"
                      @change="changedInputData = true"
                    >
                    </el-time-picker>
                  </div>
                </el-form-item>
              </el-tooltip>
              <div class="date-buttons">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    !changedDate && !changedInputData
                      ? 'These are already the default values.'
                      : 'All filters return to their default values.'
                  "
                  placement="bottom"
                  :hide-after="2500"
                >
                  <el-button
                    size="mini"
                    @click="setDatesBasedOnRouteData"
                    :disabled="!changedDate && !changedInputData"
                    >Default</el-button
                  >
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Change filters to submit."
                  placement="bottom"
                  :disabled="changedInputData"
                  :hide-after="2500"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="submit = true"
                    :disabled="!changedInputData"
                    >Submit</el-button
                  ></el-tooltip
                >
              </div>
              <el-form-item label="Laps Shown">
                <el-switch
                  v-model="routesWithErrorsActive"
                  active-color="#1F4258"
                  inactive-color="#1F4258"
                  active-text="Invalid"
                  inactive-text="Valid"
                >
                </el-switch>
              </el-form-item>
            </div>
            <div class="route-information-container">
              <div
                v-if="charts.loading"
                v-loading="true"
                class="charts-loading"
              />
              <div v-else>
                <div class="route-information">
                  <b>Total Valid Laps </b>
                  {{ totalValidRoutes + "/" + validRoutes }}
                </div>
                <div class="route-information">
                  <b>Total Invalid Laps </b>
                  {{ totalErrorRoutes + "/" + errorRoutes }}
                </div>
                <div class="route-information">
                  <b>Average Duration </b> {{ getAverageRouteDuration }}
                </div>

                <div class="route-information">
                  <b>Fastest Lap </b> {{ getFastestRoute }}
                </div>
                <div class="route-information">
                  <b>Slowest Lap </b> {{ getSlowestRoute }}
                </div>
                <div
                  class="route-information"
                  v-if="this.dataInfo?.startDate !== null"
                >
                  <b>Start Date </b> {{ getStartDate }}
                </div>
                <div class="route-information">
                  <b> Downloads </b>
                  <div class="download-buttons">
                    <el-button
                      style="width: 110px"
                      type="success"
                      size="mini"
                      @click="downloadCSV(false)"
                      >Instances</el-button
                    >
                    <el-button
                      style="width: 110px"
                      type="success"
                      size="mini"
                      @click="downloadCSV(true)"
                      >Histogram</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div v-if="charts.loading" v-loading="true" class="charts-loading" />
      <h4 v-else-if="!selectedRouteId && !isAnchorPrimary" class="empty-data">
        Select a route to see its data.
      </h4>
      <h4 v-else-if="!selectedAnchorId && isAnchorPrimary" class="empty-data">
        Select a Gateway to see its data.
      </h4>
      <h4
        v-else-if="
          showEmptyData || (!routeSelectedForFilter && isAnchorPrimary)
        "
        class="empty-data"
      >
        No data for this time period.
      </h4>
      <template v-else>
        <h2
          @click="showOverallChart = !showOverallChart"
          v-if="charts.overall.length"
        >
          Histogram - Time spent per lap
          <span v-if="hasFilterStack" @click="popPreviousStack"
            ><i class="el-icon-back" /> Previous</span
          >
        </h2>
        <div v-if="showOverallChart && charts.overall.length">
          <div class="select-items">
            <span class="select-checkbox">
              <el-checkbox
                size="large"
                border="border"
                v-model="selectMultiple"
              >
                <b>Select Interval </b>
              </el-checkbox>
            </span>
            <span class="done-btn">
              <el-button type="primary" @click="finishedSelection = true"
                >Done</el-button
              >
            </span>
          </div>
          <overall-chart
            ref="overallChart"
            :list="charts.overall"
            :selectMultiple="selectMultiple"
            :finishedSelection="finishedSelection"
            :analyticsData="charts.analyticsData"
            :resetGraph="resetGraph"
            build-bin
            @has-filter-stack="value => (hasFilterStack = value)"
            @filter-interval="filterInterval"
            @done="resetSelection"
            @change-data="changeData"
            @reseted-graph="resetGraph = false"
          />
        </div>
        <div
          class="stats-route-small-charts"
          v-if="charts.stayedTrip.data.length"
        >
          <div class="stats-route-small-charts__chart">
            <h2 @click="showAverageTimeChart = !showAverageTimeChart">
              Average time on checkpoint (Heatmap)
            </h2>
            <div v-if="showAverageTimeChart">
              <bar-chart
                :data="charts.stayedTrip.data"
                :stayed="charts.stayed.data"
                :trip="charts.trip.data"
                :changeValue="true"
                :stayedIdxs="stayedIdxs"
                :tripIdxs="tripIdxs"
                :labels="charts.stayedTrip.labels"
              />
              <div class="label-wrapper">
                <div class="wrapper">
                  <span class="square-one"></span
                  ><span
                    >Average time spent travelling to the next checkpoint</span
                  >
                </div>
                <div class="wrapper">
                  <span class="square-two"></span
                  ><span>Average time spent on a checkpoint</span>
                </div>
              </div>
            </div>
          </div>
          <div class="stats-route-small-charts__chart">
            <h2 @click="showFrequencyChart = !showFrequencyChart">
              Frequency on checkpoint (Heatmap)
            </h2>
            <div v-if="showFrequencyChart">
              <bar-chart
                :data="checkpointFrequency"
                :labels="charts.stayedTrip.labels"
                :yLabel="'Count'"
              />
            </div>
          </div>
          <!--<div class="stats-route-small-charts__chart">
            <h2>Average time spent on checkpoint</h2>
            <bar-chart
              :data="charts.stayed.data"
              :labels="charts.stayed.labels"
            />
          </div>

          <div class="stats-route-small-charts__chart">
            <h2>Average time spent traveling to the next checkpoint</h2>
            <bar-chart :data="charts.trip.data" :labels="charts.trip.labels" />
          </div>-->

          <div class="graph-border">
            <p
              class="first-header"
              v-if="routesPerHour.data.length || durationPerHour.data.length"
            >
              <b>Hour Analytics</b>
            </p>
            <div
              class="stats-route-small-charts__chart"
              v-if="routesPerHour.data.length"
            >
              <h2
                @click="showTotalLapsPerHourChart = !showTotalLapsPerHourChart"
              >
                Total Laps per Hour Interval
              </h2>
              <div v-if="showTotalLapsPerHourChart">
                <bar-chart
                  :data="routesPerHour.data"
                  :labels="routesPerHour.labels"
                  :xLabel="'Hour Interval (hh:mm)'"
                  :yLabel="'Quantity'"
                  :days="numberOfDays"
                />
              </div>
            </div>

            <div
              class="stats-route-small-charts__chart"
              v-if="durationPerHour.data.length"
            >
              <h2
                @click="
                  showAverageDurationPerHourChart = !showAverageDurationPerHourChart
                "
              >
                Average Duration of Laps per Hour Interval
              </h2>
              <div v-if="showAverageDurationPerHourChart">
                <bar-chart
                  :data="durationPerHour.data"
                  :labels="durationPerHour.labels"
                  :xLabel="'Hour Interval (hh:mm)'"
                />
              </div>
            </div>
          </div>

          <div class="graph-border" v-if="routeInstances?.length">
            <p class="first-header"><b>Latest Laps</b></p>
            <el-table
              :data="routeInstances"
              border
              max-height="900"
              class="route-instances-table"
            >
              <el-table-column label="Gateway" :resizable="false">
                <template slot-scope="scope">
                  {{ scope.row.anchorLabel }}
                </template>
              </el-table-column>

              <el-table-column label="Lap ID" :resizable="false">
                <template slot-scope="scope">
                  {{ scope.row.id }}

                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Analyse Route"
                    placement="right"
                    :hide-after="2500"
                  >
                    <i
                      @click="redirectToAnalytics(scope.row.id)"
                      class="el-icon-right"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Start Date" :resizable="false">
                <template slot-scope="scope">
                  {{
                    moment
                      .tz(scope.row.startDate * 1000, clientData.timezone)
                      .format("MMM DD YYYY, HH:mm")
                  }}
                </template>
              </el-table-column>
              <el-table-column label="End Date" :resizable="false">
                <template slot-scope="scope">
                  {{
                    moment
                      .tz(scope.row.endDate * 1000, clientData.timezone)
                      .format("MMM DD YYYY, HH:mm")
                  }}
                </template>
              </el-table-column>
              <el-table-column label="Duration" :resizable="false">
                <template slot-scope="scope">
                  <span v-if="scope.row.duration">
                    {{
                      moment.utc(scope.row.duration * 1000).format("HH") +
                        "h" +
                        moment.utc(scope.row.duration * 1000).format("mm") +
                        "m"
                    }}
                  </span>
                  <span v-else>
                    <hr />
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Error" :resizable="false">
                <template slot-scope="scope">
                  <span v-if="scope.row.error === 0">
                    <hr />
                  </span>
                  <span v-if="scope.row.error === 1">
                    Timeout.
                  </span>
                  <span
                    v-if="
                      scope.row.error === 2 ||
                        scope.row.error === 3 ||
                        scope.row.error === 5
                    "
                  >
                    Wrong Path.
                  </span>
                  <span v-if="scope.row.error === 4">
                    Offline Gateway.
                  </span>
                  <span v-if="scope.row.error === 6">
                    Route interrupted.
                  </span>
                  <span v-if="scope.row.error === 7">
                    Min. duration not reached.
                  </span>
                  <span v-if="scope.row.error > 7">
                    Unknown error.
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import routesApi from "@/modules/routes/api/routes.api";
import positionsApi from "@/modules/position-logs/api/position-logs.api";
import overallChart from "@/views/stats/components/overall-chart";
import * as routerTypes from "@/router/router.types";
import barChart from "@/views/stats/components/bar-chart";
import { mapState, mapActions, mapGetters } from "vuex";
import { cloneDeep, create } from "lodash";

export default {
  components: {
    overallChart,
    barChart
  },

  props: {
    routeSelected: {
      required: true
    },
    routeId: {
      default: null
    },
    anchorId: {
      default: null
    },
    routeList: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("anchors", {
      routes: "routes",
      anchor: "anchor"
    }),
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapGetters("auth", ["isAdmin"]),

    getAverageRouteDuration() {
      if (this.dataInfo.dataList.length > 0) {
        const totalSeconds = this.dataInfo.dataList.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        const average = totalSeconds / this.dataInfo.dataList.length;
        return (
          moment.utc(average * 1000).format("HH") +
          "h" +
          moment.utc(average * 1000).format("mm") +
          "m"
        );
      } else return "0";
    },

    totalErrorRoutes() {
      if (!this.routesWithErrorsActive) return 0;
      else return this.dataInfo.dataList.length;
    },

    totalValidRoutes() {
      if (this.routesWithErrorsActive) return 0;
      else return this.dataInfo.dataList.length;
    },

    getStartDate() {
      return moment
        .tz(
          moment.utc(this.dataInfo.startDate * 1000),
          this.clientData.timezone
        )
        .format("DD/MM - HH:mm")
        .toString();
      /*return (
        moment
          .unix(this.dataInfo.startDate)
          .day()
          .toString() +
        "/" +
        moment
          .unix(this.dataInfo.startDate)
          .month()
          .toString() +
        " - " +
        moment
          .unix(this.dataInfo.startDate)
          .hours()
          .toString() +
        ":" +
        moment
          .unix(this.dataInfo.startDate)
          .minutes()
          .toString()
      );*/
    },
    getFastestRoute() {
      if (this.dataInfo.dataList.length)
        return (
          moment.utc(this.dataInfo.dataList[0] * 1000).format("HH") +
          "h" +
          moment.utc(this.dataInfo.dataList[0] * 1000).format("mm") +
          "m"
        );
      else return 0;
    },

    getSlowestRoute() {
      if (this.dataInfo.dataList.length)
        return (
          moment
            .utc(
              this.dataInfo.dataList[this.dataInfo.dataList.length - 1] * 1000
            )
            .format("HH") +
          "h" +
          moment
            .utc(
              this.dataInfo.dataList[this.dataInfo.dataList.length - 1] * 1000
            )
            .format("mm") +
          "m"
        );
      else return 0;
    },

    anchorList() {
      return this.resources.data.anchors;
    }
  },

  data() {
    return {
      anchorRoutesHistory: null,
      border: true,
      changedInputData: false,
      changedDate: false,
      changedRoute: false,
      charts: {
        loading: true,
        overall: [],
        stayed: {
          data: [],
          labels: []
        },
        analyticsData: [],
        trip: {
          data: [],
          labels: []
        },
        stayedTrip: {
          data: [],
          labels: []
        }
      },
      checkpointFrequency: [],
      dataFromRequest: [],
      dataInfo: {
        dataList: [],
        startDate: null
      },
      dateSelected: [
        this.$route.name === routerTypes.ROUTE_STATS_ROUTE &&
        this.routeSelected?.startDate
          ? moment()
              .clone()
              .subtract(14, "days")
              .startOf("day")
              .valueOf() /
              1000 >
            this.routeSelected.startDate
            ? moment()
                .clone()
                .subtract(14, "days")
                .startOf("day")
                .format("yyyy-MM-DD")
            : moment.unix(this.routeSelected.startDate).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD"),
        moment().format("yyyy-MM-DD")
      ],
      durationPerHour: {
        data: [],
        labels: []
      },
      endDuration: moment.utc(0).format("HH:mm"),
      endHour: moment.utc(86399 * 1000).format("HH:mm"),
      errorRoutes: 0,
      finishedSelection: false,
      firstTime: true,
      foundRoute: null,
      hasFilterStack: false,
      hasSwitched: false,
      hasUpdatedAnchors: false,
      isAnchorPrimary: false,
      maxDuration: 3600,
      minDuration: 0,
      moment,
      numberOfDays: null,
      originalData: [],
      pageSize: 50,
      pickerOptions: {
        disabledDate: null,
        shortcuts: null
      },
      resetGraph: false,
      routeAnchors: [],
      // routeList: [],
      routeInstances: [],
      routesPerHour: {
        data: [],
        labels: []
      },
      routeSelectedForFilter: null,
      routesWithErrorsActive: false,
      routesWithAnchors: [],
      routesToShow: [],
      responseData: null,
      selectedAnchorId: null,
      selectedAnchors: [],
      selectedAnchorsRoutesHistory: [],
      selectLoading: true,
      selectMultiple: false,
      selectedRouteId: null,
      showAverageDurationPerHourChart: true,
      showAverageTimeChart: true,
      showEmptyData: false,
      showFrequencyChart: true,
      showOverallChart: true,
      showTotalLapsPerHourChart: true,
      startDuration: moment.utc(0).format("HH:mm"),
      startHour: moment.utc(0).format("HH:mm"),
      stayedIdxs: [],
      submit: false,
      totalDurationPerHour: {
        data: [],
        labels: []
      },
      showToggleText: "Show More",
      tripIdxs: [],
      validRoutes: 0
    };
  },

  watch: {
    "$route.params.routeId"(newValue) {
      if (this.routeList.length) {
        this.selectedRouteId = newValue;
        this.$forceUpdate();
      }
    },
    routeList: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length) {
          if (this.$route.params.routeId) {
            this.selectedRouteId = this.$route.params.routeId;
            this.$forceUpdate();
          }
          this.routesWithAnchors = this.routeList.filter(
            route => route.anchors && route.anchors.length
          );
        }
      }
    },
    selectedRouteId: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.routeList.length) {
          this.selectLoading = false;
          this.handleSelectedRoute(newValue);
          this.$forceUpdate();
        }
      }
    },
    routeSelected: {
      handler(newValue) {
        if (newValue?.id && this.foundRoute) {
          this.setDatesBasedOnRouteData();
          this.setPickerOptions();
          this.generateChartsData();
          this.resetGraph = false;
          this.submit = false;
          this.changedRoute = true;
        }
      }
    },
    dateSelected: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.changedRoute) {
          this.generateChartsData();
          this.submit = false;
          this.changedRoute = false;
        }
      }
    },
    submit: {
      handler(newValue) {
        this.errorRoutes = 0;
        if (newValue) {
          this.generateChartsData();
          this.submit = false;
          this.$emit("done");
        }
      }
    },
    closedMenu: {
      handler(newValue) {
        if (newValue) this.resetGraph = true;
      }
    },
    routesWithErrorsActive: {
      handler(newValue) {
        this.hasSwitched = true;
        this.generateChartsData();
        this.hasSwitched = false;
        this.$emit("done");
      }
    },
    isAnchorPrimary: {
      handler(newValue) {
        if (newValue === false) {
          this.selectedAnchorId = null;
          this.anchorRoutesHistory = [];
          this.routeSelectedForFilter = null;
          this.$refs.mySelect.selectedLabel = "";
          this.selectedRouteId =
            this.anchor.data.activeRoute?.id ?? this.routeList[0].id;
          // this.handleSelectedRoute(this.selectedRouteId);
        } else if (newValue === true) {
          this.selectedRouteId = null;
          this.selectedAnchors = [];
          this.routeAnchors = [];
          this.selectedAnchorId = this.anchor.data.id;
          this.onAnchorSelected(this.anchor.data.id);
        }
      }
    }
  },

  methods: {
    resetSelection() {
      this.finishedSelection = false;
      this.selectMultiple = false;
      return;
    },

    onRouteSelectedForFilterChange(id) {
      const routeFromHistory = this.anchorRoutesHistory.find(
        route => route.id === id
      );

      if (routeFromHistory) {
        const routeIndex = this.routeList.findIndex(
          route => route.id === routeFromHistory.routeId
        );

        if (routeIndex !== -1) {
          const updatedRouteInfo = { ...this.routeList[routeIndex] };
          updatedRouteInfo.startDate = routeFromHistory.startDate;
          updatedRouteInfo.endDate = routeFromHistory.endDate;

          this.$emit("route-selected-filter-change", updatedRouteInfo);
        }
      }
    },

    redirectToAnalytics(lapId) {
      this.$router.push({
        name: routerTypes.ROUTE_STATS_GLOBALS,
        params: { lapId: lapId }
      });
    },

    getCreatedDate(anchorId) {
      const anchor =
        this.selectedAnchorsRoutesHistory.find(
          anchor => anchor.anchorId == anchorId
        ) ?? null;

      if (anchor) {
        return (
          " (" + moment.unix(anchor.routeCreatedDate).format("DD/MM/YYYY") + ")"
        );
      } else {
        return "";
      }
    },

    getRouteDate(item) {
      return (
        item.label +
        " (" +
        moment.unix(item.startDate).format("DD/MM/YYYY") +
        ")"
      );
    },

    getTimeTransformed(info) {
      return moment.unix(info).format("MMM DD YYYY, HH:mm:ss");
    },

    async downloadCSV(details) {
      const csvdata = [];
      let res = null;

      let startSeconds = 0;
      let endSeconds = 86399;
      const startDate = moment.utc(this.dateSelected[0]).unix();
      let endDate = moment.utc(this.dateSelected[1]).unix();
      const SECONDS_ID_DAY = 86400;
      endDate = endDate + SECONDS_ID_DAY - 1;
      if (this.startHour !== null) {
        startSeconds =
          moment(this.startHour, "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 1;
        endSeconds =
          moment(this.endHour, "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 59;
      }

      try {
        if (details)
          res = await routesApi.getRouteDetailsCSV(
            this.routeSelected.id,
            startDate,
            endDate,
            this.minDuration,
            this.maxDuration,
            this.isAnchorPrimary
              ? [this.selectedAnchorId]
              : this.selectedAnchors
          );
        else
          res = await routesApi.getRouteInstancesCSV(
            this.routeSelected.id,
            startDate,
            endDate,
            this.minDuration,
            this.maxDuration,
            startSeconds,
            endSeconds,
            !this.routesWithErrorsActive,
            this.isAnchorPrimary
              ? [this.selectedAnchorId]
              : this.selectedAnchors
          );
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(res.data);
      anchor.target = "_blank";
      if (details) anchor.download = "routes-analytics.csv";
      else anchor.download = "routes-instances.csv";
      anchor.click();
    },

    resetHours() {
      //const sum = this.charts.overall.reduce((a, b) => a + b, 0);
      this.startHour = moment.utc(0).format("HH:mm");
      this.startDuration = moment.utc(0).format("HH:mm");
      this.endDuration = "03:00"; //TODO CHANGE
      //this.endDuration = moment
      //.utc((sum / this.charts.overall.length) * 5 * 1000)
      //.format("HH:mm");
      //const endDuration = moment.duration(this.endDuration).asSeconds();
      //this.charts.overall = this.charts.overall.filter(
      //elem => elem < endDuration
      //);
      this.endHour = moment.utc(86399 * 1000).format("HH:mm");
      this.submit = true;
    },

    setDatesBasedOnRouteData() {
      // const endDate = this.routeSelected.endDate
      //   ? moment.unix(this.routeSelected.endDate)
      //   : moment();
      // const TWO_WEEKS_OLD_SINCE_END_DATE = endDate
      //   .clone()
      //   .subtract(14, "days")
      //   .startOf("day");
      // let startDate = this.routeSelected.endDate
      //   ? moment.unix(this.routeSelected.startDate)
      //   : TWO_WEEKS_OLD_SINCE_END_DATE;

      // if (
      //   moment
      //     .unix(this.routeSelected.startDate)
      //     .isSameOrAfter(TWO_WEEKS_OLD_SINCE_END_DATE)
      // ) {
      //   startDate = moment.unix(this.routeSelected.startDate);
      // }

      const endDate = this.routeSelected.endDate
        ? moment.unix(this.routeSelected.endDate)
        : moment();

      const TWO_WEEKS_OLD_SINCE_END_DATE = endDate
        .clone()
        .subtract(14, "days")
        .startOf("day");

      let startDate = TWO_WEEKS_OLD_SINCE_END_DATE;

      if (
        moment
          .unix(this.routeSelected.startDate)
          .isSameOrAfter(TWO_WEEKS_OLD_SINCE_END_DATE)
      ) {
        startDate = moment.unix(this.routeSelected.startDate);
      }

      this.dateSelected = [
        startDate.format("yyyy-MM-DD"),
        endDate.format("yyyy-MM-DD")
      ];
      this.startHour = moment.utc(0).format("HH:mm");
      this.endHour = moment.utc(86399 * 1000).format("HH:mm");

      const sum = this.charts.overall.reduce((a, b) => a + b, 0);
      this.startDuration = "00:00";
      this.endDuration = "03:00";
      this.changedDate = false;
      this.changedInputData = false;
    },

    setPickerOptions() {
      const shortcuts = [];
      const last2Weeks = {
        text: "Last 2 weeks",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
          picker.$emit("pick", [start, end]);
        }
      };

      const lastMonth = {
        text: "Last month",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
        }
      };

      const last3Months = {
        text: "Last 3 months",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
        }
      };

      const TWO_WEEKS_SINCE_TODAY = moment()
        .clone()
        .subtract(14, "days")
        .startOf("day");

      const ONE_MONTH_SINCE_TODAY = moment()
        .clone()
        .subtract(30, "days")
        .startOf("day");

      const THREE_MONTHS_SINCE_TODAY = moment()
        .clone()
        .subtract(90, "days")
        .startOf("day");

      const startDateMoment = moment.unix(this.routeSelected.startDate);

      if (
        this.routeSelected.endDate === null ||
        startDateMoment.isSame(moment())
      ) {
        if (startDateMoment.isBefore(THREE_MONTHS_SINCE_TODAY)) {
          shortcuts.push(last2Weeks, lastMonth, last3Months);
        } else if (startDateMoment.isBefore(ONE_MONTH_SINCE_TODAY)) {
          shortcuts.push(last2Weeks, lastMonth);
        } else if (startDateMoment.isBefore(TWO_WEEKS_SINCE_TODAY)) {
          shortcuts.push(last2Weeks);
        }
      }
      this.pickerOptions.shortcuts = shortcuts.length ? shortcuts : null;
    },

    async generateChartsData() {
      if (
        (!this.selectedRouteId && !this.isAnchorPrimary) ||
        (!this.selectedAnchorId && this.isAnchorPrimary)
      ) {
        this.charts.loading = false;
        return;
      }

      const endDate = moment
        .utc(moment.tz(this.dateSelected[1], this.clientData.timezone))
        .unix();
      const startDate = moment
        .utc(moment.tz(this.dateSelected[0], this.clientData.timezone))
        .unix();
      //const SECONDS_ID_DAY = 86400;
      //endDate = endDate + SECONDS_ID_DAY - 1;

      let startSeconds = 0;
      let endSeconds = 86399;

      this.charts.loading = true;
      this.charts.overall = [];
      this.charts.stayed = {
        data: [],
        labels: []
      };
      this.charts.trip = {
        data: [],
        labels: []
      };
      this.charts.stayedTrip = {
        data: [],
        labels: []
      };
      this.routesPerHour = {
        data: [],
        labels: []
      };
      const mappedData = [];
      const submit = this.submit;

      try {
        let res = null;

        if (this.startHour !== null) {
          startSeconds =
            moment(this.startHour, "HH:mm").diff(
              moment().startOf("day"),
              "seconds"
            ) + 1;
          endSeconds =
            moment(this.endHour, "HH:mm").diff(
              moment().startOf("day"),
              "seconds"
            ) + 59;
        } else {
          startSeconds = 0;
          endSeconds = 86399;
        }
        if (this.startDuration || this.endDuration) {
          this.minDuration = moment.duration(this.startDuration).asMinutes();
          this.maxDuration = moment.duration(this.endDuration).asMinutes();
        }

        if (this.hasSwitched || this.hasUpdatedAnchors) {
          res = { data: this.dataFromRequest };
        } else {
          res = await routesApi.getRouteInstancesByRouteId(
            this.routeSelected.id,
            startDate,
            endDate,
            this.minDuration,
            this.maxDuration,
            startSeconds,
            endSeconds,
            this.isAnchorPrimary
              ? [this.selectedAnchorId]
              : this.selectedAnchors
          );
          this.dataFromRequest = res.data;
          this.originalData = res.data;
        }

        if (!this.routesWithErrorsActive) {
          this.routeInstances = res.data.filter(element => element.error === 0);
          this.routeInstances.sort(function(a, b) {
            if (a.endDate === b.endDate) return 0;
            else return a.endDate < b.endDate ? 1 : -1;
          });
        } else {
          this.routeInstances = res.data.filter(element => element.error != 0);
          this.routeInstances.sort(function(a, b) {
            if (a.endDate === b.endDate) return 0;
            else return a.endDate < b.endDate ? 1 : -1;
          });
        }

        let tempCheckpointFrequency = [];
        this.checkpointFrequency = [];
        if (res.data)
          if (res.data[0])
            tempCheckpointFrequency = new Array(
              this.routeSelected.tags.length
            ).fill(0);

        if (res.data.length) {
          this.showEmptyData = false;
          this.responseData = res.data;
          this.charts.overall = [];
          this.charts.analyticsData = [];
          this.errorRoutes = 0;
          this.validRoutes = 0;
          res.data.forEach(element => {
            if (!this.routesWithErrorsActive) {
              if (element.error === 0) {
                this.charts.overall.push(element.duration);
                if (submit)
                  this.charts.analyticsData.push({
                    duration: element.duration,
                    startDate: element.startDate
                  });

                for (let i = 0; i < element.tags.length; i++) {
                  if (!element.tags[i].skipped) {
                    tempCheckpointFrequency[i]++;
                  }
                }
                this.validRoutes += 1;
              } else {
                this.errorRoutes += 1;
              }
            } else {
              if (element.error != 0) {
                this.charts.overall.push(element.duration);
                if (submit)
                  this.charts.analyticsData.push({
                    duration: element.duration,
                    startDate: element.startDate
                  });

                for (let i = 0; i < element.tags.length; i++) {
                  if (!element.tags[i].skipped) {
                    tempCheckpointFrequency[i]++;
                  }
                }
                this.errorRoutes += 1;
              } else {
                this.validRoutes += 1;
              }
            }
          });

          if (this.firstTime) {
            const sum = this.charts.overall.reduce((a, b) => a + b, 0);
            this.startDuration = "00:00";
            this.endDuration = "03:00";
            const endDuration = moment.duration(this.endDuration).asSeconds();
            this.charts.overall = this.charts.overall.filter(
              elem => elem < endDuration
            );
            this.firstTime = false;
          }

          this.groupByHour(res.data);

          this.checkpointFrequency = tempCheckpointFrequency;

          if (!this.routesWithErrorsActive) {
            res.data = res.data.filter(element => element.error === 0);
          } else {
            res.data = res.data.filter(element => element.error != 0);
          }

          if (JSON.stringify(mappedData) !== JSON.stringify([])) {
            this.charts.stayed = this.generatedChartDataByKey(
              mappedData,
              "stayed",
              []
            );
            this.charts.trip = this.generatedChartDataByKey(
              mappedData,
              "trip",
              []
            );

            this.charts.stayedTrip = this.generatedChartDataByKey(
              mappedData,
              "stayed+trip",
              []
            );
          } else {
            this.charts.stayed = this.generatedChartDataByKey(
              res.data,
              "stayed",
              []
            );

            this.charts.trip = this.generatedChartDataByKey(
              res.data,
              "trip",
              []
            );

            this.charts.stayedTrip = this.generatedChartDataByKey(
              res.data,
              "stayed+trip",
              []
            );
          }

          if (
            !this.charts.stayedTrip.length &&
            !this.charts.overall.length &&
            !this.routesPerHour.data.length &&
            !this.durationPerHour.data.length &&
            !this.routeInstances.length
          ) {
            this.showEmptyData = true;
          }
        } else {
          this.dataInfo.dataList = [];
          this.validRoutes = 0;
          this.errorRoutes = 0;
          this.showEmptyData = true;
        }
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.charts.loading = false;
        this.changedRoute = false;
        this.changedInputData = false;
        this.$emit("stayed-trip", this.charts.stayedTrip.data);
        this.$emit("checkpoint-frequency", this.checkpointFrequency);
      }
    },

    popPreviousStack() {
      this.$refs.overallChart.popFilterStack();
    },

    changeData(data) {
      this.dataInfo = data;
    },

    filterInterval(limits) {
      this.checkpointFrequency = this.generateCheckpointInterval(
        this.responseData,
        limits
      );
      this.charts.stayed = this.generatedChartDataByKey(
        this.responseData,
        "stayed",
        limits
      );
      this.charts.trip = this.generatedChartDataByKey(
        this.responseData,
        "trip",
        limits
      );
      this.charts.stayedTrip = this.generatedChartDataByKey(
        this.responseData,
        "stayed+trip",
        limits
      );
      if (!this.routesWithErrorsActive) {
        this.routeInstances = this.dataFromRequest.filter(
          element =>
            element.error === 0 &&
            element.duration >= limits[0] &&
            element.duration <= limits[1]
        );
        this.routeInstances.sort(function(a, b) {
          if (a.endDate === b.endDate) return 0;
          else return a.endDate < b.endDate ? 1 : -1;
        });
      } else {
        this.routeInstances = this.dataFromRequest.filter(
          element =>
            element.error != 0 &&
            element.duration >= limits[0] &&
            element.duration <= limits[1]
        );
        this.routeInstances.sort(function(a, b) {
          if (a.endDate === b.endDate) return 0;
          else return a.endDate < b.endDate ? 1 : -1;
        });
      }
    },

    groupByHour(resData) {
      const labels = [
        "00:00 - 03:00",
        "03:00 - 06:00",
        "06:00 - 09:00",
        "09:00 - 12:00",
        "12:00 - 15:00",
        "15:00 - 18:00",
        "18:00 - 21:00",
        "21:00 - 23:59"
      ];
      const data = new Array(8).fill(0);
      const durationData = new Array(8).fill(0);

      const startDate = moment
        .tz(this.dateSelected[0], this.clientData.timezone)
        .unix();
      let endDate = moment
        .tz(this.dateSelected[1], this.clientData.timezone)
        .unix();
      const SECONDS_IN_DAY = 86400;
      endDate = endDate + SECONDS_IN_DAY - 1;
      let durationDataAverage = [];

      resData.forEach(element => {
        if (!this.routesWithErrorsActive) {
          if (element.error === 0) {
            data[
              Math.floor(
                moment
                  .tz(moment.unix(element.endDate), this.clientData.timezone)
                  .hours() / 3
              )
            ]++;
            durationData[
              Math.floor(
                moment
                  .tz(moment.unix(element.endDate), this.clientData.timezone)
                  .hours() / 3
              )
            ] += element.duration;
          }
        } else {
          if (element.error != 0) {
            data[
              Math.floor(
                moment
                  .tz(moment.unix(element.endDate), this.clientData.timezone)
                  .hours() / 3
              )
            ]++;
            durationData[
              Math.floor(
                moment
                  .tz(moment.unix(element.endDate), this.clientData.timezone)
                  .hours() / 3
              )
            ] += element.duration;
          }
        }
      });

      if (this.routeSelected.endDate !== null) {
        this.numberOfDays = moment
          .unix(endDate)
          .diff(moment.unix(startDate), "days");

        if (this.numberOfDays === 0) this.numberOfDays = 1;

        durationDataAverage = durationData.map((element, index) =>
          Math.round(element / data[index])
        );
      } else {
        this.numberOfDays = moment
          .unix(endDate)
          .diff(moment.unix(startDate), "days");

        if (this.numberOfDays === 0) this.numberOfDays = 1;

        durationDataAverage = durationData.map((element, index) => {
          if (data[index] !== 0) return Math.round(element / data[index]);
          else {
            return 0;
          }
        });
      }

      if (durationDataAverage.some(data => data !== 0)) {
        this.durationPerHour = {
          data: durationDataAverage,
          labels: labels
        };
      }
      if (data.some(data => data !== 0)) {
        this.routesPerHour = {
          data: data,
          labels: labels
        };
      }
    },

    generateCheckpointInterval(data, [min = 0, max = Number.MAX_SAFE_INTEGER]) {
      let result = [];
      if (data) if (data[0]) result = new Array(data[0].tags.length).fill(0);
      data.forEach(entry => {
        if (entry.duration >= min && entry.duration <= max) {
          entry.tags.forEach((tag, index) => {
            if (tag.stayed || tag.trip) {
              result[index]++;
            }
          });
        }
      });
      return result;
    },

    generatedChartDataByKey(
      resData,
      key,
      [min = 0, max = Number.MAX_SAFE_INTEGER]
    ) {
      const generatedData = {};
      const labels = [];
      const data = [];
      resData.forEach(entry => {
        if (entry.duration > min && entry.duration <= max) {
          entry.tags.forEach(tag => {
            const fullTagInfo = this.routeSelected.tags.find(
              _tag => tag.ord === _tag.ord
            );

            if (fullTagInfo) {
              if (!(fullTagInfo.ord in generatedData)) {
                generatedData[fullTagInfo.ord] = {
                  sum: 0,
                  count: 0,
                  skipped: false
                };
              }
              const [key1, key2] = key.split("+");
              generatedData[fullTagInfo.ord].sum += tag[key1];
              if (key2) {
                generatedData[fullTagInfo.ord].sum += tag[key2];
              }
              generatedData[fullTagInfo.ord].count++;
              generatedData[fullTagInfo.ord].skipped = tag["skipped"];
            }
          });
        }
      });

      let localMax = 0;
      for (const [key, value] of Object.entries(generatedData)) {
        if (value.sum / value.count > localMax) {
          localMax = value.sum / value.count;
        }
      }

      /*
        for (const [localKey, value] of Object.entries(generatedData)) {
        labels.push(localKey);
        if ((value.sum / value.count) < (0.01 * localMax) && (key === 'stayed' || key === 'trip') && (value.sum / value.count !== 0)) {
          if (key === 'stayed')
            this.stayedIdxs.push([value.sum / value.count, i])
          if (key === 'trip')
            this.tripIdxs.push([value.sum / value.count, i])
          data.push(0.01 * localMax);
        }
        else {
          data.push(value.sum / value.count);
        }
        i++;
      }
      

      */

      let i = 0;
      // Generate values
      for (const [localKey, value] of Object.entries(generatedData)) {
        labels.push(localKey);
        if (
          value.sum / value.count < 0.02 * localMax &&
          (key === "stayed" || key === "trip") &&
          !value.skipped
        ) {
          if (key === "stayed")
            this.stayedIdxs.push([value.sum / value.count, i]);
          if (key === "trip") this.tripIdxs.push([value.sum / value.count, i]);
          //data.push(0.02 * localMax);
          data.push(0); //TODO: confirmar isto
        } else {
          data.push(value.sum / value.count);
        }
        i++;
      }

      return {
        labels,
        data
      };
    },

    handleSelectedRoute(routeId) {
      if (routeId !== "") {
        this.selectedAnchors = [];
        this.selectedAnchorsRoutesHistory = [];
        this.foundRoute = this.routesWithAnchors.find(
          route => route.id == routeId
        );

        if (!this.foundRoute) {
          this.foundRoute = this.routeList.find(route => route.id == routeId);
          this.routesToShow = cloneDeep(this.routeList);
          this.showToggleText = "Show Less";
        } else {
          this.routesToShow = cloneDeep(this.routesWithAnchors);
          this.showToggleText = "Show More";
        }

        this.routeAnchors = this.foundRoute?.anchors ?? [];

        this.selectedAnchors =
          this.routeAnchors.length > 0
            ? this.routeAnchors.map(anchors => anchors.id)
            : [];

        if (this.selectedAnchors.length) {
          this.selectedAnchors.forEach(anchorId =>
            this.fetchRouteHistory(anchorId)
          );
        }

        if (this.foundRoute) {
          this.$emit("route-selected-filter-change", this.foundRoute);
        }
      }
    },

    switchIsAnchorPrimary() {
      this.isAnchorPrimary = !this.isAnchorPrimary;
    },

    async fetchRouteHistory(anchorId) {
      try {
        const res = await routesApi.getRouteHistory(anchorId);
        const routesHistory = res.data;

        if (this.isAnchorPrimary) {
          this.anchorRoutesHistory = routesHistory;

          if (this.anchorRoutesHistory.length > 0) {
            this.routeSelectedForFilter = this.anchorRoutesHistory[0].id;
            this.onRouteSelectedForFilterChange(this.routeSelectedForFilter);
          }
        } else {
          const route =
            routesHistory.find(
              route => route.routeId == this.routeSelected.id
            ) ?? null;
          this.selectedAnchorsRoutesHistory.push({
            anchorId: anchorId,
            routeCreatedDate: route?.startDate ?? null
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onAnchorSelected(anchorId) {
      try {
        await this.fetchRouteHistory(anchorId);
      } catch (error) {
        console.log(error);
      }
    },

    onClearRoute() {
      this.selectedAnchors = [];
      this.routeAnchors = [];
      this.$emit("route-selected-filter-change", {});
      this.$emit("stayed-trip", []);
      this.$emit("checkpoint-frequency", []);
    },

    onClearAnchor() {
      this.anchorRoutesHistory = [];
      this.routeSelectedForFilter = null;
      this.onClearRoute();
    },

    updateData() {
      if (this.selectedAnchors.length) {
        const anchorIds = this.anchorList
          .filter(anchor => this.selectedAnchors.includes(anchor.id))
          .map(anchor => anchor.anchorId);
        this.dataFromRequest = this.originalData.filter(anchor =>
          anchorIds.includes(anchor.anchorId)
        );
        this.hasUpdatedAnchors = true;
        this.generateChartsData();
        this.hasUpdatedAnchors = false;
        this.$emit("done");
      }
    },
    formatTime(targetTime) {
      if (targetTime != null) {
        return moment.utc(targetTime * 1000).format("HH:mm");
      } else return "--";
    },
    handleToggleRoutesList() {
      if (this.showToggleText === "Show More") {
        this.routesToShow = cloneDeep(this.routeList);
        this.showToggleText = "Show Less";
      } else {
        this.routesToShow = cloneDeep(this.routesWithAnchors);
        this.showToggleText = "Show More";
      }
      if (this.routeSelected.id) {
        this.onClearRoute();
      }
      this.$refs.routesSelector.toggleMenu();
    }
  }

  // async created() {
  //   // this.routeSelectedForFilter = this.selectedRouteId;
  //   try {
  //     await this.fetchRouteList();

  //  mounted() {
  //       // if (this.routeList.length) {
  //         if (this.$route.params.routeId) {
  //           console.log("here")
  //           this.isAnchorPrimary = false;
  //           this.selectedRouteId = this.$route.params.routeId;
  //           // this.handleSelectedRoute(this.routeSelected.id)
  //           // this.handleSelectedRoute(this.selectedRouteId);
  //         } else if (!this.isAnchorPrimary && !this.$route.params.routeId) {
  //           console.log("here 2")
  //           this.selectedRouteId =
  //             this.anchor.data.activeRoute?.id ?? this.routeList[0].id;
  //           // this.handleSelectedRoute(this.selectedRouteId);
  //         }
  //       // }
  //     // });
  // }
};
</script>

<style lang="scss">
.stats-charts {
  clear: both;
  padding: 12px 12px;

  .container {
    @media (max-width: 580px) {
      padding: 0 14px;
    }
  }

  .time-pickers {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .el-table__row td:first-child .cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-icon-right {
    border: 1px;
    border-style: solid;
    border-radius: 50%;
    padding: 2px;
  }

  .switch {
    margin-right: 40px;
    margin-bottom: 10px;
  }

  .el-form-item__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;

    @media (max-width: 580px) {
      width: 100%;
    }
  }

  // .route-information-container {
  //   padding-top: 5px;
  // }

  .route-information {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 40px;
    justify-content: left;
    text-align: left;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    .download-buttons {
      @media (max-width: 580px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 4px;
      }
    }
  }

  @media (max-width: 900px) {
    .route-information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 0px;
    }
  }

  .form-select {
    display: grid;
    justify-content: center;
    grid-gap: 32px;
    grid-template-columns: 35% 4% 34%;
    padding: 24px 0 30px;
    width: 860px;

    @media (max-width: 840px) {
      grid-template-columns: 100%;
      justify-items: center;
      gap: 15px;
      width: 90%;

      .arrow-left-right-button {
        transform: rotate(90deg);
      }
    }

    @media (max-width: 580px) {
      width: 100%;
    }

    .arrow-left-right-button {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      padding: 10px 7px;
      height: 35px;
      width: 35px;
    }

    .el-select {
      .el-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .circular {
          width: 25px;
          height: 25px;
        }
      }
    }

    .el-select .el-tag {
      max-width: 150px;
    }

    .el-select__tags {
      max-height: 57px;
      max-width: 180px !important;
      overflow-y: auto;
      overflow-x: hidden;

      .el-tag.el-tag--info {
        color: $--color-primary;

        .el-tag__close {
          background-color: $--color-primary;
          color: #fff;
        }

        .el-tag__close:hover {
          background-color: #79cfff;
          color: #fff;
        }
      }
    }

    .self-end-item {
      justify-items: self-end !important;

      @media (max-width: 840px) {
        justify-items: start !important;
      }
    }

    #route-select {
      .el-input__inner {
        width: 225px;
        height: 40px !important;

        @media (max-width: 840px) {
          width: 220px;
        }
      }
    }

    .el-form-item {
      display: flex;
      grid-template-columns: 0 27% 73%;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @media (max-width: 840px) {
        display: grid;
        grid-template-columns: 0 26% 67%;
        width: 400px;
        align-items: center;
        justify-content: center;
        justify-items: start;

        .el-input__inner {
          width: 220px;
          justify-items: start;
        }
      }

      @media (max-width: 580px) {
        width: 100%;
      }

      .selected-anchors {
        width: 200px !important;

        @media (max-width: 1770px) {
          width: auto;
        }
      }
    }
  }

  .filter-container {
    display: grid;
    grid-template-columns: 47% 50%;
    width: 860px;
    justify-content: center;

    @media (max-width: 1770px) {
      padding-left: 20px;
    }
    @media (max-width: 900px) {
      grid-template-columns: 100%;
      width: 90%;
    }
    @media (max-width: 580px) {
      width: 100%;
      padding-left: 0;
    }
  }

  .filter-container-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 12px;
    align-items: center;
  }

  .el-form .el-form-item label.el-form-item__label {
    line-height: 40px;
    text-transform: none;
    font-weight: bold;
    font-size: 16px;
    color: $--color-primary;
  }

  .page-title {
    font-weight: bold;
  }

  .el-form-item {
    margin-bottom: 2px;
  }

  .picker-form-item {
    @media (max-width: 580px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .el-date-editor {
    width: 240px !important;

    @media (max-width: 580px) {
      width: 100% !important;
    }
  }

  .date-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .route-instances-table {
    margin-top: 15px;
  }

  .descriptors {
    display: flex;
    justify-content: right;
    align-self: center;
  }

  .time-picker-wrappers {
    display: flex;
    justify-content: center;

    @media (max-width: 580px) {
      width: 100%;
    }

    .el-date-editor,
    .el-input__inner {
      width: 120px !important;

      @media (max-width: 580px) {
        width: 100% !important;
      }
    }
  }

  .icon-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
  }

  .select-items {
    display: flex;
    float: right;
  }

  .position-info-admin {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 16% 16% 16% 16% 18% 18%;
    margin-left: 2%;
    grid-gap: 10px;
    color: #444;
    text-align: center;
  }

  .position-info {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    margin-left: 2%;
    grid-gap: 10px;
    color: #444;
    text-align: center;
  }

  .label-wrapper {
    font-size: 15px;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: left;
    margin-top: 1%;

    .wrapper {
      display: flex;
      flex-direction: row;
    }

    span {
      display: flex;
    }

    .square-one {
      display: flex;
      height: 13px;
      width: 13px;
      background-color: #788d9a;
      margin-top: 3px;
      margin-right: 8px;
      margin-left: 2px;
    }
    .square-two {
      display: flex;
      height: 13px;
      width: 13px;
      background-color: #1f4258;
      margin-top: 3px;
      margin-right: 8px;
      margin-left: 2px;
    }
  }

  .tag-info {
    display: flex;
    justify-content: center;
    align-items: center;

    &__line {
      width: 60%;
      color: white;
    }
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 8px;
    text-align: left;
    border: 3px solid #fff;
    box-shadow: 1px 1px 1px 1px #c9d1d6;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;

    span {
      float: right;
      cursor: pointer;
      font-size: 20px;
      position: relative;
      top: 5px;
    }
  }

  .charts-loading {
    margin-top: 10%;
  }

  .download-csv {
    margin-bottom: 15px;

    div {
      margin-bottom: 10px;
    }
  }

  .graph-border {
    margin-top: 30px;
    border-top: 2px solid grey;

    .first-header {
      text-align: center;
      margin-top: 30px;
      font-size: 24px;
    }
  }

  .fastest-slowest {
    margin-top: 5px;
  }

  .analytics-info {
    margin-right: 10px;
    font-size: 20px;
  }

  canvas {
    display: block;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    max-height: 400px;
    max-width: calc(100% - 48px);
  }

  .empty-data {
    padding-top: 50px;
  }
}
</style>
