<template>
  <div class="user">
    <div class="container-settings">
      <el-tabs type="border-card" v-model="activeTab">
        <el-tab-pane
          v-if="!isChangePasswordUser && isClientDefault"
          label="Place Beacons"
          name="beacons-location"
          ><userBeaconsLocation
        /></el-tab-pane>
        <el-tab-pane label="Account / Users" name="account"
          ><user-account
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser"
          label="Gateways"
          name="anchors"
          ><userAnchors
        /></el-tab-pane>

        <el-tab-pane
          v-if="!isChangePasswordUser"
          :label="
            isTracker
              ? 'Locations & Assets'
              : isClientDefault
              ? 'Locations'
              : 'Tags'
          "
          name="locations"
          ><userBeacons :batteryIcon="batteryIcon"
        /></el-tab-pane>
        <el-tab-pane
          v-if="
            (local === 'local' && !isChangePasswordUser) || !isClientDefault
          "
          :label="isClientDefault ? 'Beacon History' : 'Tag History'"
          name="beacon-history"
          ><user-beacon-history
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser"
          label="Wireless Networks"
          name="ssids"
          ><user-ssids
        /></el-tab-pane>
        <el-tab-pane
          v-if="isAdmin && !isChangePasswordUser && isClientDefault"
          label="Layout"
          name="layout"
          ><user-layout
        /></el-tab-pane>
        <el-tab-pane
          v-if="isAdmin && !isChangePasswordUser"
          label="API Key"
          name="api-key"
          ><user-api-key
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser && isClientDefault"
          label="Documentation"
          name="faq"
          ><user-faq
        /></el-tab-pane>
        <!--<el-tab-pane v-if="isAdmin" label="Webhooks" name="webhook"
          ><user-webhook
        /></el-tab-pane>-->
      </el-tabs>
    </div>
    <el-dialog
      title="Discard Changes"
      :visible.sync="unsavedChangesDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <span class="warning"
        >You have unsaved changes! <br />
        Are you sure you want to discard them? <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="continueEditing()" size="small"
          >Continue Editing</el-button
        >
        <el-button type="danger" @click="discardChangesFunction" size="small"
          >Discard Changes</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions, mapState } from 'vuex'
import * as actionTypes from '@/store/action-types.ts'
import * as routerTypes from "@/router/router.types.ts";
import userAccount from "@/views/user/components/user-account.vue";
import userAnchors from "@/views/user/components/user-anchors.vue";
import userBeacons from "@/views/user/components/user-beacons.vue";
import userBeaconsLocation from "@/views/user/components/user-beacons-location.vue";
import userApiKey from "@/views/user/components/user-api-key.vue";
import userBeaconHistory from "@/views/user/components/user-beacon-history.vue";
import userSsids from "@/views/user/components/user-ssids.vue";
import userLayout from "@/views/user/components/user-layout.vue";
import userFaq from "@/views/user/components/user-faq.vue";
import batteryIcon from "@/components/battery-icon.vue";
//import userWebhook from "@/views/user/components/user-webhook.vue";

export default {
  components: {
    userAccount,
    userAnchors,
    userBeacons,
    userBeaconsLocation,
    userSsids,
    userApiKey,
    userBeaconHistory,
    userLayout,
    userFaq
    //userWebhook
  },

  computed: {
    ...mapGetters("auth", ["isAdmin", "isChangePasswordUser", "isTracker"]),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      discardChanges: "discardChanges"
    }),
    ...mapGetters("clients", ["isClientDefault"])
  },

  data() {
    return {
      activeTab: routerTypes.ROUTE_USER_MAIN_TAB,
      local: process.env.NODE_ENV,
      unsavedChangesDialog: false,
      batteryIcon: batteryIcon
    };
  },

  watch: {
    '$route': {
      deep: true,
      handler () {
        if (!this.$route.params.tab) {
          this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB;
        }
      },
    },
    '$route.params.tab': {
      deep: true,
      handler (newValue) {
        this.activeTab = newValue || routerTypes.ROUTE_USER_MAIN_TAB;
      }
    },
    isClientDefault: {
      immediate: true,
      async handler(newValue) {
        if (!newValue)
          this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB_STATIONARY
        else
          this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB;
      }
    },
    activeTab: {
      handler(newTab) {
        this.$router.push({
          name: "user",
          params: { tab: newTab }
        }).catch(_ => {
          // Do nothing
        })
      }
    },
    discardChanges(newValue) {
      if (newValue === true) {
        this.unsavedChangesDialog = true;
      }
    }
  },

  methods: {
    ...mapActions("clients", {
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS,
      getUsers: actionTypes.CLIENTS_GET_USERS,
      getSsids: actionTypes.CLIENTS_GET_SSIDS
    }),
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD
    }),
    continueEditing() {
      this.unsavedChangesDialog = false;
      this.setDiscard(false);
    },
    discardChangesFunction() {
      this.setDiscard(false);
      this.setIsChanging(false);
    },
  },

  beforeMount() {
    if (this.isClientDefault)
      this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB_STATIONARY
    if (
      this.$route.name === routerTypes.ROUTE_USER_CHANGE_PASSWORD &&
      this.$route.params.tab !== routerTypes.ROUTE_USER_MAIN_TAB
    ) {
      return this.$router.push({
        name: "user",
        params: { tab: routerTypes.ROUTE_USER_MAIN_TAB }
      });
    }

    if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab;
    } else {
      if (!this.isChangePasswordUser)
        this.$router.push({
          name: "user",
          params: { tab: this.activeTab }
        });
      else
        this.$router.push({
          name: "user",
          params: { tab: routerTypes.ROUTE_USER_ACCOUNT }
        });
    }
  },

  created () {
    const userClientId = this.user.data.clientId
   // this.getAnchors(userClientId)
    this.getUsers(userClientId)
    this.getSsids(userClientId)
  }
};
</script>

<style lang="scss">
.user {
  padding: 24px;

  @media (max-width: 860px) {
    padding: 48px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-settings {
    max-width: 80vw;
    margin: 0 10vw 0 10vw;

    @media (max-width: 860px) {
      max-width: 90vw;
      margin: 0 5px 0 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
