<template>
  <div
    class="routes"
    v-loading="loadingRoutesPage"
    element-loading-background="#F0F5FB"
  >
    <template>
      <dashboard
        :show-anchors="false"
        :enumerate-tags="
          activeTabName === 'editor'
            ? editTableData
            : activeTabName === 'creator'
            ? createTableData
            : activeRoute?.tags ?? []
        "
        :interval-update="false"
        :is-editing-route="true"
        :show-numbered-only="showNumberedOnly"
        @update-show-tabs="updateShowTabs"
        @tag-clicked="onTagClicked"
      >
        <div class="sidebar">
          <div v-if="showTabs">
            <el-tabs v-model="activeTabName" @tab-click="scrollToActiveRoute">
              <el-tab-pane label="Manage" name="manage">
                <RoutesManager
                  :routeList="routeList"
                  :scrollToCard="scrollToCard"
                  @update-tab-name="updateTabName"
                  @fetch-route-list="fetchRouteList"
                  @set-dialog-discard="setDialogDiscard"
                  @delete-route="deleteRoute"
                />
              </el-tab-pane>
              <el-tab-pane label="Edit" name="editor">
                <RoutesEditor
                  :routeList="routeList"
                  :tableData="editTableData"
                  :editingRoute="editingRoute"
                  :discardEditRoute="discardEditRoute"
                  :routeToEdit="selectedRoute"
                  :activeTabName="activeTabName"
                  @fetch-route-list="fetchRouteList"
                  @change-table-data="changeEditTableData"
                  @set-dialog-save="setDialogSave"
                  @set-dialog-discard="setDialogDiscard"
                  @update-editing-route="editingRoute = false"
                  @update-discard-edit-route="discardEditRoute = false"
                />
              </el-tab-pane>
              <el-tab-pane label="Create" name="creator">
                <RoutesCreator
                  :tableData="createTableData"
                  :creatingNewRoute="creatingNewRoute"
                  :discardCreateRoute="discardCreateRoute"
                  @fetch-route-list="fetchRouteList"
                  @change-table-data="changeCreateTableData"
                  @set-dialog-save="setDialogSave"
                  @set-dialog-discard="setDialogDiscard"
                  @update-creating-new-route="creatingNewRoute = false"
                  @update-discard-create-route="discardCreateRoute = false"
                />
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </dashboard>
    </template>
    <el-dialog
      title="Save Changes"
      :visible.sync="certifyEditTagDialogSave"
      width="500px"
    >
      <span class="warning"
        >Are you sure you want to save the changes that you made? <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certifyEditTagDialogSave = false" size="small"
          >Continue Editing</el-button
        >
        <el-button type="primary" @click="confirmSave" size="small"
          >Save Changes</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Discard Changes"
      :visible.sync="certifyEditTagDialogDiscard"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="continueEditing()"
      width="500px"
    >
      <span class="warning"
        >You have unsaved changes! <br />
        Are you sure you want to discard them? <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="continueEditing()" size="small"
          >Continue Editing</el-button
        >
        <el-button type="danger" @click="discardChangesFunction" size="small"
          >Discard Changes</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="'Delete ' + routeToDelete.label"
      :visible.sync="certifyDeleteRoute"
      :close-on-click-modal="false"
      @close="certifyDeleteRoute = false"
      width="500px"
    >
      <span class="warning"
        >This action will delete the route and all information related to it.
        <br />
        Are you sure you want to delete '{{
          routeToDelete?.label ?? "Route " + routeToDelete?.id
        }}'? <br />
        <br />
      </span>
      <span>Type 'Delete' into the input box to confirm. </span>
      <el-input
        class="delete-input"
        placeholder="Delete"
        v-model="typeDelete"
        v-loading="loadingDelete"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certifyDeleteRoute = false" size="small"
          >Cancel</el-button
        >
        <el-button
          :disabled="typeDelete != 'Delete'"
          type="danger"
          @click="deleteRouteConfirmed"
          size="small"
          >Delete</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import Dashboard from "@/views/Dashboard";
import RoutesManager from "@/components/routes-components/RoutesManager";
import RoutesEditor from "@/components/routes-components/RoutesEditor";
import RoutesCreator from "@/components/routes-components/RoutesCreator";
import RoutesApi from "@/modules/routes/api/routes.api";

export default {
  name: "Routes",

  components: {
    Dashboard,
    RoutesManager,
    RoutesEditor,
    RoutesCreator
  },

  data() {
    return {
      activeTabName: "manage",
      certifyEditTagDialogSave: false,
      certifyEditTagDialogDiscard: false,
      createTableData: [],
      creatingNewRoute: false,
      discardCreateRoute: false,
      discardEditRoute: false,
      editingRoute: false,
      editTableData: [],
      loadingDelete: false,
      loadingRoutesPage: true,
      routeList: [],
      scrollToCard: false,
      selectedRoute: null,
      showNumberedOnly: false,
      showTabs: true,
      routeToDelete: {},
      certifyDeleteRoute: false,
      typeDelete: ""
    };
  },

  computed: {
    ...mapState("clients", {
      selectedAnchor: "selectedAnchor"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources",
      isChangingInformation: "isChangingInformation",
      discardChanges: "discardChanges",
      activeRoute: "activeRoute"
    }),
    ...mapGetters("auth", ["isAdmin"])
  },

  watch: {
    discardChanges(newValue) {
      if (newValue === true) {
        this.certifyEditTagDialogDiscard = true;
      }
    },
    activeTabName: {
      handler(newValue) {
        if (newValue === "creator") {
          this.updateActiveRoute(null);
        } else if (!this.activeRoute) {
          this.updateActiveRoute(this.selectedAnchor?.activeRoute ?? null);
        }
      }
    }
  },

  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD,
      setPath: actionTypes.USER_SET_PATH,
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),

    continueEditing() {
      this.certifyEditTagDialogDiscard = false;
      this.setDiscard(false);
      this.setPath("routes");
    },

    async confirmSave() {
      if (this.activeTabName === "editor") {
        this.editingRoute = true;
      } else if (this.activeTabName === "creator") {
        this.creatingNewRoute = true;
      }
      this.certifyEditTagDialogSave = false;
    },

    hasBeenPushed(id) {
      const tableData =
        this.activeTabName === "editor"
          ? this.editTableData
          : this.activeTabName === "creator"
          ? this.createTableData
          : [];
      if (tableData.length > 0)
        if (tableData[tableData.length - 1].id === id) return true;

      return false;
    },

    onTagClicked(tag) {
      if (!this.isAdmin) {
        return;
      }
      if (this.activeTabName === "editor") {
        if (!this.editTableData) {
          this.editTableData = [];
        }
        if (!this.hasBeenPushed(tag.id) && tag.parentId == null) {
          if (this.editTableData.length === 1)
            this.startingBeacon = tag.lastPosition.id;
          this.setIsChanging(true);
        }
      } else if (this.activeTabName === "creator") {
        if (!this.createTableData) {
          this.createTableData = [];
        }
        if (!this.hasBeenPushed(tag.id) && tag.parentId == null) {
          this.createTableData.push({
            id: tag.id,
            tagId: tag.tagId,
            label: tag.label,
            tagPositionId: tag.lastPosition.id,
            ord: this.createTableData.length + 1
          });
          if (this.createTableData.length === 1)
            this.startingBeacon = tag.lastPosition.id;
          this.setIsChanging(true);
        }
      }
    },

    discardChangesFunction() {
      if (this.activeTabName === "editor") {
        this.discardEditRoute = true;
      } else if (this.activeTabName === "creator") {
        this.discardCreateRoute = true;
      }

      this.certifyEditTagDialogDiscard = false;
      this.setDiscard(false);
      this.setIsChanging(false);
    },

    async fetchRouteList() {
      try {
        const res = await RoutesApi.getRouteList();
        this.routeList = res.data;
        this.routeList.sort((a, b) => {
          if (b.anchors.length !== a.anchors.length)
            return b.anchors.length - a.anchors.length;
          else return b.startDate - a.startDate;
        });
      } catch (error) {
        console.log(error);
      }
    },

    updateTabName(tabName, routeId) {
      this.selectedRoute = routeId;
      this.activeTabName = tabName;
    },

    changeCreateTableData(newTableData) {
      this.createTableData = newTableData;
    },

    changeEditTableData(newTableData) {
      this.editTableData = newTableData;
    },

    deleteRoute(routeId, routeLabel) {
      this.certifyDeleteRoute = true;
      this.routeToDelete = {
        id: routeId,
        label: routeLabel
      };
    },

    async deleteRouteConfirmed() {
      try {
        const res = await RoutesApi.deleteRoute(this.routeToDelete.id);

        if (res.data) {
          this.loadingDelete = true;
          await this.fetchRouteList();
        }
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.loadingDelete = false;
        this.certifyDeleteRoute = false;
        this.$notify({
          title: "Success",
          message: "Route successfully deleted",
          type: "success"
        });
        this.updateActiveRoute(null);
      }
    },

    setDialogSave(booleanValue) {
      this.certifyEditTagDialogSave = booleanValue;
    },

    setDialogDiscard(booleanValue) {
      this.certifyEditTagDialogDiscard = booleanValue;
    },

    updateShowTabs(booleanValue) {
      this.showTabs = booleanValue;
    },

    scrollToActiveRoute() {
      if (this.activeTabName == "manage") {
        this.scrollToCard = true;
      } else {
        this.scrollToCard = false;
      }
    }
  },

  async created() {
    await this.fetchRouteList();
    this.loadingRoutesPage = false;
    this.setIsChanging(false);
    this.updateActiveRoute(this.selectedAnchor?.activeRoute ?? null);
  }
};
</script>

<style lang="scss">
.routes {
  @media (max-width: 470px) {
    .dashboard .toggle-dashboard-slot,
    .dashboard .toggle-dashboard-slot--close {
      top: 161px;
    }

    .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
      top: 41px;
    }

    .dashboard__fit-to-bounds-no-anchor {
      top: 163px;
    }

    .dashboard__tag-slider-no-anchor {
      top: 200px;
    }
  }

  .delete-input {
    margin-top: 1.5vh;
  }

  .sidebar {
    display: block !important;
    position: fixed;
    top: 113px;
    right: 0;
    bottom: 0;
    padding-bottom: 20px;
    background: $--border-color-extra-light;
    width: 636px;
    max-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 100;

    .page-title {
      font-weight: bold;
    }

    @media (max-width: $md) {
      width: 100%;
      padding-top: 26px;
    }

    .el-tabs__header {
      margin: 15px 40px 0px;

      @media (max-width: $sm) {
        margin: 15px 19px 0px;
      }
    }

    .el-tabs--top .el-tabs__item.is-top {
      font-weight: bold;
      font-size: 16px;
    }

    .el-range-editor--small.el-input__inner {
      width: 300px;
    }
  }
}
</style>
