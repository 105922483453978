<template>
  <div class="metrics">
    <el-card>
      <div slot="header" :style="computeColor">
        <div class="content">
          <div class="left-content">
            <span
              class="constraint-icon"
              v-if="
                usageInfo.constraintTag != null ||
                  usageInfo.constraintShiftStart != 0 ||
                  usageInfo.constraintShiftEnd != 86399
              "
            >
              <i
                :class="
                  usageInfo.constraintTag != null
                    ? 'el-icon-location-outline'
                    : usageInfo.constraintShiftStart != 0 ||
                      usageInfo.constraintShiftEnd != 86399
                    ? 'el-icon-time'
                    : ''
                "
              >
              </i>
            </span>
            <h3 class="route-label">{{ usageInfo.routeLabel }}</h3>
          </div>
          <span
            style="float: right; font-size: 14px"
            v-if="
              usageInfo.constraintShiftStart != 0 ||
                usageInfo.constraintShiftEnd != 86399
            "
          >
            {{ formatTime(usageInfo.constraintShiftStart) }} -
            {{ formatTime(usageInfo.constraintShiftEnd) }}
          </span>
          <span
            style="float: right; font-size: 14px"
            v-else-if="usageInfo.constraintTag"
          >
            {{ usageInfo.constraintTag }}
          </span>
        </div>
      </div>
      <div class="time-based-metrics">
        <span class="date">Today</span>
        <div class="card-data">
          <div class="card-data__valid">
            <i class="el-icon-top"></i>
            {{
              " " +
                (usageInfo.dCount || 0) +
                " valid " +
                "(" +
                getPercentage(
                  usageInfo.dCount,
                  usageInfo.dCount + usageInfo.dCountError
                ) +
                "%)"
            }}
          </div>
          <div class="card-data__error">
            <i class="el-icon-bottom"></i>
            {{
              " " +
                (usageInfo.dCountError || 0) +
                " errors " +
                " (" +
                getPercentage(
                  usageInfo.dCountError,
                  usageInfo.dCount + usageInfo.dCountError
                ) +
                "%)"
            }}
          </div>

          <div class="card-data__average-duration">
            <b>
              {{
                usageInfo.dAvg
                  ? moment.utc(usageInfo.dAvg * 1000).format("HH") +
                    "h" +
                    moment.utc(usageInfo.dAvg * 1000).format("mm") +
                    "m"
                  : "No info of"
              }}
            </b>
            Avg Duration
          </div>
        </div>
      </div>

      <div class="time-based-metrics">
        <span class="date">Last Two Weeks</span>

        <div class="card-data">
          <div class="card-data__valid">
            <i class="el-icon-top"></i>
            {{
              " " +
                (usageInfo.twCount || 0) +
                " valid " +
                "(" +
                getPercentage(
                  usageInfo.twCount,
                  usageInfo.twCount + usageInfo.twErrors
                ) +
                "%)"
            }}
          </div>
          <div class="card-data__error">
            <i class="el-icon-bottom"></i>
            {{
              " " +
                (usageInfo.twErrors || 0) +
                " errors " +
                " (" +
                getPercentage(
                  usageInfo.twErrors,
                  usageInfo.twCount + usageInfo.twErrors
                ) +
                "%)"
            }}
          </div>

          <div class="card-data__average-duration">
            <b>
              {{
                usageInfo.twAvg
                  ? moment.utc(usageInfo.twAvg * 1000).format("HH") +
                    "h" +
                    moment.utc(usageInfo.twAvg * 1000).format("mm") +
                    "m"
                  : "No info of"
              }}
            </b>
            Avg Duration
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "MetricsCard",

  props: {
    usageInfo: {
      type: Object,
      default: () => ({})
    },
    isActiveRoute: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moment
    };
  },
  computed: {
    computeColor() {
      return `
        background-color: ${this.isActiveRoute ? "#BEEACA" : "#DCDFE6"}; 
        color: ${this.isActiveRoute ? "#3C8300" : "#545454"}; 
        height: 100%;
        width: 100%;
        display: flex;
        `;
    }
  },
  methods: {
    getPercentage(divisor, dividend) {
      if (dividend)
        return Number.parseFloat((divisor / dividend) * 100).toFixed(1);
      else return 0;
    },
    formatTime(targetTime) {
      if (targetTime != null) {
        return moment.utc(targetTime * 1000).format("HH:mm");
      } else return "--";
    }
  }
};
</script>

<style lang="scss">
.metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;

  @media (max-width: $sm) {
    width: 100%;
    padding: 0px 13px;
  }

  .el-card {
    width: 100%;
    border-radius: 5px;

    &__header {
      display: flex;
      padding: 0;
      border-bottom: none;

      .content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 9px 15px;

        .left-content {
          display: flex;

          .constraint-icon {
            font-size: 14px;
            padding-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .route-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            font-weight: bold;
            text-align: left;
            word-break: break-word;
          }
        }
      }
    }
    &__body {
      display: flex;
      justify-content: space-between;
      padding: 14px 18px 24px;

      @media (max-width: $sm) {
        padding: 15px 19px 15px 20px;
        gap: 3px;
      }

      .time-based-metrics {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .date {
          font-size: 16px;
          font-weight: bold;
        }

        .card-data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          font-size: 14px;

          &__valid {
            color: #008000;
            font-weight: bold;
          }
          &__error {
            color: #ff0000;
          }
          &__average-duration {
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
