<template>
  <div class="asset-manager" v-loading.lock="loadingAssets">
    <div class="asset-manager__head" v-if="!loadingAssets">
      <h2>Asset Manager</h2>
    </div>
    <div class="asset-manager__content" v-if="!loadingAssets">
      <div class="text-container">
        <span class="title">Metrics</span>
        <span class="subtitle">Last Update: {{ lastUpdate }}</span>
      </div>
      <div class="asset-cards">
        <AssetCard
          tagsType="Inside"
          :tagList="insideTags"
          :toHex="toHex"
          :tagToHighlight="tagToHighlight"
          :percentage="getPercentage(insideTags)"
        />
        <AssetCard
          tagsType="Outside"
          :tagList="outsideTags"
          :toHex="toHex"
          :tagToHighlight="tagToHighlight"
          :percentage="getPercentage(outsideTags)"
        />
        <AssetCard
          tagsType="Warning"
          :tagList="warningTags"
          :toHex="toHex"
          :tagToHighlight="tagToHighlight"
          :percentage="getPercentage(warningTags)"
        />
        <AssetCard
          tagsType="Undefined"
          :tagList="undefinedTags"
          :toHex="toHex"
          :tagToHighlight="tagToHighlight"
          :percentage="getPercentage(undefinedTags)"
        />
      </div>
      <div class="progress-bar-container">
        <span class="total"><b>Total Tags: </b>{{ tagList.length }}</span>
        <div class="progress-bars">
          <div class="progress-bar">
            <span>Inside</span>
            <el-progress
              :stroke-width="11"
              :percentage="getPercentage(this.insideTags)"
              color="#3C8300"
            ></el-progress>
          </div>
          <div class="progress-bar">
            <span>Outside</span>
            <el-progress
              :stroke-width="11"
              :percentage="getPercentage(this.outsideTags)"
              color="#1F4258"
            ></el-progress>
          </div>
          <div class="progress-bar">
            <span>Warning</span>
            <el-progress
              :stroke-width="11"
              :percentage="getPercentage(this.warningTags)"
              color="#D4BA32"
            ></el-progress>
          </div>
          <div class="progress-bar">
            <span>Undefined</span>
            <el-progress
              :stroke-width="11"
              :percentage="getPercentage(this.undefinedTags)"
              color="#A5B3BC"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tagsApi from "@/modules/tags/api/tags.api";
import AssetCard from "@/components/AssetCard.vue";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "AssetManager",
  components: {
    AssetCard
  },
  data() {
    return {
      moment,
      interval: null,
      lastUpdate: "",
      loadingAssets: true,
      tagList: [],
      tagToHighlight: "",
      insideTags: [],
      warningTags: [],
      outsideTags: [],
      undefinedTags: []
    };
  },
  computed: {
    ...mapState("clients", {
      clientData: "data",
      anchors: "anchors"
    })
  },
  methods: {
    async getAnchorTagsList() {
      try {
        const res = await tagsApi.getAnchorTagsList(600000);
        this.tagList = res.data;
        this.analyseTags();
      } catch (error) {
        console.log("Error fetching tags list:", error);
      } finally {
        if (this.loadingAssets) {
          this.loadingAssets = false;
        }
        this.lastUpdate = moment
          .tz(moment(), this.clientData.timezone)
          .format("HH:mm (MM/DD/YYYY)");
      }
    },
    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    },
    analyseTags() {
      this.outsideTags = [];
      this.insideTags = [];
      this.undefinedTags = [];
      this.warningTags = [];
      this.tagList.forEach(tag => {
        if (tag.anchorId == null) this.undefinedTags.push(tag);

        this.anchors.data.forEach(anchor => {
          if (tag.anchorId == anchor.id && anchor.anchorInfo == "entry")
            this.insideTags.push(tag);
          else if (tag.anchorId == anchor.id && anchor.anchorInfo == "out")
            this.outsideTags.push(tag);
          else if (tag.anchorId == anchor.id && anchor.anchorInfo == "buzzer")
            this.warningTags.push(tag);
        }, this);
      }, this);
    },
    getPercentage(tagsArray) {
      const totalTags = this.tagList.length;
      if (totalTags > 0) {
        const tagQuantity = tagsArray.length;

        if (tagQuantity === 0) {
          return 0;
        }

        const tagPerc = (tagQuantity * 100) / totalTags;
        const roundedTagPerc = parseFloat(tagPerc.toFixed(2));
        return roundedTagPerc;
      } else return 0;
    }
  },
  created() {
    this.interval = setInterval(this.getAnchorTagsList, 2000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>
<style lang="scss">
.asset-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  min-height: calc(100vh - 61px);

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 55px 24px 15px;
    flex-wrap: wrap;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 28px 30px 30px;
    gap: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;

    @media (max-width: 580px) {
      width: 75vw;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-self: self-start;

      .title {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        align-self: flex-start;
        color: #000000;
      }

      .subtitle {
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        align-self: flex-start;
        color: #545454;
      }
    }

    .asset-cards {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 500px;
      gap: 22px;
    }

    .progress-bar-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 20px 22px;
      width: 83%;
      transition: width ease-in-out 0.2s;

      @media (max-width: 580px) {
        width: 62vw;
      }

      .total {
        font-size: 16px;
        color: #000000;
      }

      .progress-bars {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        .el-progress__text {
          font-size: 14px !important;
        }

        .progress-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .el-progress-bar {
          width: 180px;
          margin: 0;
          padding: 0;
          width: 100%;
        }

        .el-progress {
          display: grid;
          grid-template-columns: 85% 15%;
          width: 304px;

          @media (max-width: 540px) {
            grid-template-columns: 80% 20%;
            width: 70%;
          }
        }
      }
    }
  }
}
</style>
