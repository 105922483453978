
import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import * as routerTypes from "../router/router.types";
import * as actionTypes from "../store/action-types";
import authApi from "../modules/auth/api/auth.api";

export default Vue.extend({
  data() {
    return {
      routerTypes,
      activeLink: this.$route.name?.split("-")[0] || ""
    };
  },

  computed: {
    ...mapState("clients", {
      anchors: "anchors",
      selectedAnchor: "selectedAnchor",
      globalView: "globalView",
      clientData: "data"
    }),
    ...mapState("user", {
      isChangingInformation: "isChangingInformation",
      pathToChangeTo: "pathToChangeTo",
      discardChanges: "discardChanges"
    }),
    ...mapState("auth", {
      user: "user"
    }),

    ...mapGetters("auth", ["isChangePasswordUser", "isTracker", "isSuperUser"]),
    ...mapGetters("clients", ["isClientDefault"])
  },

  watch: {
    $route: {
      immediate: true,
      handler(newValue) {
        //     if(newValue != '' && this.pathToChangeTo != ''){
        //       this.activeLink = newValue.name?.split("-")[0] || ""
        //       if (this.activeLink === "dashboard") this.updateGlobalView(false);
        //   }
        if (newValue.name != "") {
          this.setPath(newValue.name);
        }
      }
    },

    isChangingInformation: {
      handler(newValue) {
        if (
          newValue === false &&
          this.pathToChangeTo != "" &&
          this.$route.name != this.pathToChangeTo
        ) {
          this.$router.push({ name: this.pathToChangeTo });
        }
      }
    },

    pathToChangeTo: {
      handler(newValue) {
        if (newValue != "") {
          this.activeLink =
            newValue === "stats" || newValue === "stats-route"
              ? "stats"
              : newValue;
        } else {
          this.activeLink = this.$route.name?.split("-")[0] || "";
        }
        if (this.activeLink === "dashboard") this.updateGlobalView(false);
      }
    }

    // pathToChangeTo: {
    //   handler(newValue) {
    //     console.log(newValue);
    //     this.activeLink =
    //       newValue != "" ? newValue : this.$route.name?.split("-")[0] || "";
    //     if (this.activeLink === "dashboard") this.updateGlobalView(false);
    //   }
    // }
  },

  methods: {
    ...mapActions({
      resetStore: "resetStore"
    }),

    ...mapActions("clients", {
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR,
      updateGlobalView: actionTypes.CLIENTS_UPDATE_GLOBAL_VIEW
    }),

    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD,
      setPath: actionTypes.USER_SET_PATH
    }),

    changeView(newView: string) {
      if (!this.isChangingInformation) {
        if (newView == routerTypes.ROUTE_USER)
          if (this.isClientDefault)
            this.$router.push({
              name: routerTypes.ROUTE_USER,
              params: { tab: routerTypes.ROUTE_USER_MAIN_TAB }
            });
          else
            this.$router.push({
              name: routerTypes.ROUTE_USER,
              params: { tab: routerTypes.ROUTE_USER_MAIN_TAB_STATIONARY }
            });
        else this.$router.push({ name: newView });
        this.setIsChanging(false);
      } else {
        this.setDiscard(true);
        this.setPath(newView);
      }
    },

    async switchSuperUser(clientId: number) {
      const res = await authApi.switchSuperUser({
        userId: 1,
        newClientId: clientId
      });
      if (res.status == 200) window.location.reload();
    },

    handleDropdownCommand(command: string) {
      if (command !== "check-all" && command !== "global-view") {
        this.updateSelectedAnchor(command);
        !this.globalView
          ? ""
          : this.isClientDefault
          ? this.$router.push({ name: routerTypes.ROUTE_DASHBOARD })
          : this.user.data.clientType == 1
          ? this.$router.push({ name: routerTypes.ROUTE_BEACON_LIVE_DATA })
          : this.$router.push({ name: routerTypes.ROUTE_CARGO_CHECK });
        this.updateGlobalView(false);
      } else if (command === "global-view") {
        this.updateGlobalView(true);
        this.$router.push({ name: routerTypes.ROUTE_GLOBAL_MAP });
      }
    },

    handleStatsDropdownCommand(command: string) {
      if (!this.isChangingInformation) {
        if (this.$route.name !== command) {
          if (command === routerTypes.ROUTE_STATS_GLOBALS) {
            this.$router.push({
              name: command,
              params: {
                anchorId: this.selectedAnchor.anchorId
              }
            });
          } else {
            this.$router.push({
              name: command
            });
          }
        }
      } else {
        this.setDiscard(true);
        this.setPath(command);
      }
    },

    async logout() {
      const loading = this.$loading({
        lock: true,
        text: "Logging out"
      });
      try {
        await authApi.logout();
      } catch (_) {
        // Do nothing
      }
      setTimeout(() => {
        loading.close();
        window.localStorage.removeItem("csrf");
        window.localStorage.removeItem("map-zoom");
        window.localStorage.removeItem("map-center");
        this.resetStore();
        this.$router.push({
          name: routerTypes.ROUTE_LOGIN
        });
      }, 1250); // This setTimeout was intentionally placed due to UX purposes and logic constraints
    },

    handleCommand(command: string) {
      if (command === "logout") {
        this.logout();
      } else {
        if (command === routerTypes.ROUTE_ROUTES) {
          this.$router.push({
            name: command,
            params: { anchorId: this.selectedAnchor.anchorId }
          });
        } else if (command === routerTypes.ROUTE_USER) {
          this.$router.push({
            name: command,
            params: { tab: routerTypes.ROUTE_USER_MAIN_TAB }
          });
        } else {
          this.$router.push({ name: command });
        }
      }
    }
  }
});
