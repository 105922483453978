<template>
  <div class="asset-card">
    <el-tooltip
      :key="showTable ? 'shown' : 'hidden'"
      content="Click to see tags table"
      :disabled="showTable"
    >
      <el-card shadow="never">
        <div slot="header" @click="showTable = !showTable">
          <div class="head">
            <div class="head__icon">
              <div v-if="tagsType == 'Inside'" class="asset-icon">
                <img
                  class="inside-icon"
                  src="@/assets/map-pin-check-inside.svg"
                  alt="inside"
                />
              </div>
              <div v-else-if="tagsType == 'Outside'" class="asset-icon">
                <img
                  class="outside-icon"
                  src="@/assets/map-pin-x-inside.svg"
                  alt="outside"
                />
              </div>
              <div v-else-if="tagsType == 'Warning'" class="asset-icon">
                <img
                  class="warning-icon"
                  src="@/assets/warning.svg"
                  alt="warning"
                />
              </div>
              <div v-else-if="tagsType == 'Undefined'" class="asset-icon">
                <img
                  class="undefined-icon"
                  src="@/assets/map-pin-off.svg"
                  alt="undefined"
                />
              </div>
              <div class="stats-trending">
                <span class="trending" v-if="trendingUp || trendingDown">
                  <div v-if="trendingUp">
                    <img
                      class="trending-up-icon"
                      src="@/assets/trending-up.svg"
                      alt="trending-up"
                    />
                  </div>
                  <div v-else-if="trendingDown">
                    <img
                      class="trending-down-icon"
                      src="@/assets/trending-down.svg"
                      alt="trending-down"
                    />
                  </div>
                </span>
                <span class="percentage"
                  ><b>{{ percentage }} %</b></span
                >
              </div>
            </div>
            <div class="head__title">
              <span class="quantity">{{ tagList.length }}</span>
              <span class="tags-type"> {{ tagsType }}</span>
            </div>
          </div>
        </div>
        <div class="content-table" v-if="showTable">
          <div v-if="!tagList?.length" class="no-data">
            <span>No Data</span>
          </div>
          <el-table
            v-else
            :data="tagList"
            class="custom-table"
            height="fit-content"
          >
            <el-table-column prop="label" label="Tag" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.label">
                  {{ scope.row.label }}
                </template>
                <template v-else>
                  <span>{{ toHex(scope.row.tagId) }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="lastSeenTs" label="Last Seen" align="center">
              <template slot-scope="scope">
                <template>
                  {{ formatHour(scope.row.lastSeenTs) }}
                  <span
                    v-if="
                      scope.row.lastSeenTs &&
                        isDateBeforeToday(scope.row.lastSeenTs, clientData)
                    "
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        {{ formatDate(scope.row.lastSeenTs) }}
                      </div>
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span class="last-change">Last change: {{ lastChange }}</span>
      </el-card>
    </el-tooltip>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import { isDateBeforeToday } from "@/utils/utils";

export default {
  name: "AssetCard",
  props: {
    tagList: {
      type: Array,
      default: () => []
    },
    percentage: {
      type: Number,
      default: 0
    },
    tagsType: {
      type: String,
      default: ""
    },
    toHex: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      moment,
      isDateBeforeToday,
      lastChange: "",
      showTable: false,
      trendingDown: false,
      trendingUp: false
    };
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    })
  },
  methods: {
    formatHour(lastSeenTs) {
      return lastSeenTs
        ? moment
            .tz(moment.unix(lastSeenTs), this.clientData.timezone)
            .format("HH:mm:ss")
        : "";
    },

    formatDate(lastSeenTs) {
      return moment
        .tz(moment.unix(lastSeenTs), this.clientData.timezone)
        .format("MMMM Do YYYY, HH:mm:ss");
    }
  },
  created() {
    this.lastChange = moment
      .tz(moment(), this.clientData.timezone)
      .format("HH:mm:ss");
  },
  watch: {
    tagList: {
      handler(newValue, oldValue) {
        if (newValue.length > oldValue.length) {
          this.lastChange = moment
            .tz(moment(), this.clientData.timezone)
            .format("HH:mm:ss");
          this.trendingUp = true;
          this.trendingDown = false;
        } else if (newValue.length < oldValue.length) {
          this.lastChange = moment
            .tz(moment(), this.clientData.timezone)
            .format("HH:mm:ss");
          this.trendingUp = false;
          this.trendingDown = true;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.asset-card {
  .el-card {
    width: 218px;
    min-height: 129px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    cursor: pointer;

    @media (max-width: 579px) {
      width: 70vw;
    }

    &__header {
      padding: 0;
      border-bottom: none;

      .head {
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 18px;

        &__icon {
          display: flex;
          justify-content: space-between;

          .stats-trending {
            display: flex;
            gap: 5px;

            .percentage {
              font-size: 14px;
            }
          }

          .asset-icon {
            display: flex;
            justify-content: center;
            padding: 5px 6px;
            background-color: #ebeef5;
            border-radius: 10px;
          }
        }
        &__title {
          text-align: left;

          .quantity {
            font-size: 40px;
            word-break: break-all;
          }
          .tags-type {
            font-size: 18px;
            color: #545454;
            font-weight: bold;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 0 0 12px;

      .content-table {
        padding: 0 20px 16px;

        .no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          font-size: 12px;
          font-weight: bold;
          color: #c0c4ce;
        }
        .custom-table {
          overflow-y: auto;
          max-height: 250px;
          font-size: 12px !important;

          @media (max-width: 1630px) {
            max-height: 300px !important;
          }

          .highlight {
            background-color: #fff583 !important;
          }

          .el-icon-info {
            color: #e6a23c;
          }
        }
      }

      .last-change {
        font-size: 12px;
        text-align: start;
        color: #606266;
        padding-left: 12px;
      }
    }

    .el-table {
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;

      &::before {
        content: none;
        border: none !important;
      }

      .el-table__body {
        td {
          border: none;
          padding: 3px;
        }

        th {
          border: none;
        }
      }
      .el-table__header-wrapper {
        th {
          padding: 2px 0 5px;
        }
      }
    }
  }
}
</style>
