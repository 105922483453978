<template>
  <div class="route-card" @click="onRouteClick">
    <el-card
      :class="[
        routesLayout === 'lines' ? 'lines-layout' : 'grid-layout',
        isActiveRoute ? 'selected-background' : 'not-selected-background'
      ]"
    >
      <div slot="header">
        <div class="card-header">
          <div class="head">
            <span class="route-label">{{ route?.label ?? route?.id }}</span>
            <div class="buttons">
              <el-tooltip
                content="Lap Analytics"
                placement="top"
                :hide-after="2000"
              >
                <el-button @click="showConfirmationDialog">
                  <img src="@/assets/bar-chart.svg" alt="bar-chart" />
                </el-button>
              </el-tooltip>
              <el-tooltip
                content="Edit Route"
                placement="top"
                :hide-after="2000"
              >
                <el-button @click="updateTabName('editor')">
                  <i class="el-icon-edit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip
                content="Delete Route"
                placement="top"
                :hide-after="2000"
              >
                <el-button @click="deleteRoute()">
                  <img src="@/assets/trash.svg" alt="trash" />
                </el-button>
              </el-tooltip>
              <!-- <el-button @click="updateArchivedRoutes" v-if="isArchivedCards === false">
                <img class="archive-icon" src="@/assets/archive.svg" alt="archive"/>
              </el-button> -->
              <!-- <el-button @click="updateArchivedRoutes" v-else>
                <img class="archive-x-icon" src="@/assets/archive-x.svg" alt="archive-x"/>
              </el-button> -->
            </div>
          </div>
          <div
            class="route-time"
            v-if="
              route.constraintShiftStart != 0 ||
                route.constraintShiftEnd != 86399
            "
          >
            <span><b>Starts at: </b>{{ routeStartShift }}</span>
            <span><b>Ends at: </b>{{ routeEndShift }}</span>
          </div>
          <div class="decision-beacon" v-else-if="route.constraintTag">
            <span><b>Decision beacon: </b>{{ route.constraintTag }}</span>
          </div>
        </div>
      </div>
      <div class="anchor-selector">
        <el-select
          v-model="updatedRouteAnchors"
          placeholder="Select Gateways"
          multiple
          filterable
          default-first-option
          :disabled="isArchivedCards"
          @change="() => setIsChanging(true)"
        >
          <el-option
            :disabled="allOptionsSelected"
            :key="'selectAll'"
            :label="'SELECT ALL'"
            :value="'selectAll'"
            @click.native="selectAllAnchors"
            :style="{ fontWeight: 'bold' }"
            v-if="anchorOptions.length > 0"
          >
          </el-option>
          <el-option
            v-for="anchor in anchorOptions"
            :key="anchor.id"
            :label="anchor.label ?? anchor.id"
            :value="anchor.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="submit-button">
        <el-button
          type="primary"
          size="mini"
          @click="submitAnchors"
          :disabled="!route.id"
          >Submit</el-button
        >
      </div>
      <div class="route-date">
        <el-tooltip content="Creation Date" placement="left" :hide-after="2000">
          <span>
            {{ moment.unix(route.startDate).format("MMM DD YYYY") }}
          </span>
        </el-tooltip>
      </div>
    </el-card>
  </div>
</template>

<script>
import routesApi from "@/modules/routes/api/routes.api";
import * as routerTypes from "@/router/router.types";
import * as actionTypes from "@/store/action-types";
import { mapState, mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  name: "RouteCard",
  props: {
    route: {
      type: Object,
      default: () => ({})
    },
    anchorList: {
      type: Array,
      default: () => []
    },
    routesLayout: {
      type: String,
      default: ""
    },
    isArchivedCards: {
      type: Boolean,
      default: false
    },
    isActiveRoute: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moment,
      anchorOptions: [],
      updatedRouteAnchors: []
    };
  },
  watch: {
    route: {
      handler(newRoute) {
        this.updateAnchorOptions(newRoute);
      },
      deep: true,
      immediate: true
    },
    anchorList: {
      handler(newAnchorList) {
        this.updateAnchorOptions(this.route, newAnchorList);
      },
      deep: true,
      immediate: true
    },
    discardChanges: {
      handler(newValue, oldValue) {
        if (!newValue && oldValue && !this.isChangingInformation) {
          this.redirectToAnalytics();
        }
      }
    }
  },
  computed: {
    ...mapState("user", {
      isChangingInformation: "isChangingInformation",
      discardChanges: "discardChanges"
    }),
    allOptionsSelected() {
      return this.arraysAreEqual(
        this.anchorOptions.map(anchor => anchor.id),
        this.updatedRouteAnchors
      );
    },
    routeStartShift() {
      if (this.route.constraintShiftStart != null) {
        return moment
          .utc(this.route.constraintShiftStart * 1000)
          .format("HH:mm");
      } else {
        return "";
      }
    },
    routeEndShift() {
      if (this.route.constraintShiftEnd != null) {
        return moment.utc(this.route.constraintShiftEnd * 1000).format("HH:mm");
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD,
      setPath: actionTypes.USER_SET_PATH,
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),
    updateAnchorOptions(route, anchorList = this.anchorList) {
      this.updatedRouteAnchors = route.anchors.map(anchor => anchor.id);
      this.anchorOptions = [...route.anchors, ...anchorList];
    },
    selectAllAnchors() {
      this.updatedRouteAnchors = [
        ...this.route.anchors.map(anchor => anchor.id),
        ...this.anchorList.map(anchor => anchor.id)
      ];
    },

    async submitAnchors() {
      try {
        await routesApi.addAnchorToRoute(this.route.id, {
          anchors: this.updatedRouteAnchors
        });
        this.$notify({
          title: "Success",
          message: "Route updated successfully!",
          type: "success"
        });
        this.$emit("route-updated");
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
    },

    arraysAreEqual(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    },

    updateArchivedRoutes() {
      this.$emit("update-archived-routes", this.route);
    },

    showConfirmationDialog() {
      if (this.isChangingInformation) {
        this.setDiscard(true);
      } else {
        this.redirectToAnalytics();
      }
    },

    redirectToAnalytics() {
      const routeCommand = routerTypes.ROUTE_STATS;
      this.setPath(routeCommand);
      this.$router.push({
        name: routeCommand,
        params: { routeId: this.route.id }
      });
    },

    updateTabName(tabName) {
      this.$emit("update-tab-name", tabName, this.route.id);
    },

    deleteRoute() {
      this.$emit("delete-route", this.route.id, this.route.label);
    },

    onRouteClick() {
      this.updateActiveRoute(this.route);
    }
  },
  created() {
    this.updateAnchorOptions(this.route);
  }
};
</script>

<style lang="scss">
.route-card {
  .grid-layout {
    width: 260px;
    min-height: 220px;
    max-height: 254px;
  }

  .lines-layout {
    width: 550px;
    min-height: 220px;
    max-height: 254px;

    @media (max-width: 600px) {
      width: 90vw;
      min-height: auto;
    }
  }

  .selected-background {
    border: 1px solid $--color-primary;
  }

  .not-selected-background {
    border: none;
  }

  .el-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: start;
    cursor: pointer;

    &__header {
      border-bottom: none;
      padding: 20px 24px 0;
      min-height: 81px;

      .card-header {
        display: flex;
        flex-direction: column;
        border-bottom: none;
        gap: 6px;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .buttons {
            display: flex;
            gap: 5px;

            .el-button {
              padding: 3px 5px;
              margin-left: 0;

              .el-icon-edit {
                color: $--color-primary;
              }
            }

            .archive-x-icon,
            .archive-icon,
            .trash-icon {
              display: none;
            }
          }

          .route-label {
            font-size: 14px;
            font-weight: bold;
            color: $--color-primary;
            text-align: start;
            word-break: break-word;
          }

          .el-button:hover {
            color: aqua;
          }
        }

        .route-time {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 12px;
          color: #6e7274;
        }
      }
    }

    &__body {
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0px 24px 20px;

      .anchor-selector {
        width: 100%;
        padding-top: 15px;

        @media (max-width: 600px) {
          padding-top: 10px;
        }

        .el-select__tags::-webkit-scrollbar {
          width: 7px;
        }

        .el-select__tags::-webkit-scrollbar-track {
          background: none;
        }

        .el-select__tags::-webkit-scrollbar-thumb {
          background-color: #a5b3bc;
          border-radius: 10px;
        }

        .el-select {
          width: 100%;

          .el-button {
            span {
              padding: 12px 20px;
            }
          }

          &__input {
            height: 100%;
          }

          .el-input__inner {
            display: flex;
            align-items: center;
            padding-right: 5px;
            width: 100%;
            font-size: 12px;
            border-radius: 5px;
          }

          .el-input__suffix {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          &__tags {
            max-height: 58px;
            overflow-y: auto;
            overflow-x: hidden;

            .el-tag.el-tag--info {
              color: $--color-primary;

              .el-tag__close {
                background-color: $--color-primary;
                color: #fff;
              }

              .el-tag__close:hover {
                background-color: #79cfff;
                color: #fff;
              }
            }
          }

          .el-tag {
            max-width: 95% !important;
          }
        }
      }
      .route-date {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: bold;
        color: #6e7274;
      }
    }
  }
}
</style>
