var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"container-settings"},[_c('el-tabs',{attrs:{"type":"border-card"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(!_vm.isChangePasswordUser && _vm.isClientDefault)?_c('el-tab-pane',{attrs:{"label":"Place Beacons","name":"beacons-location"}},[_c('userBeaconsLocation')],1):_vm._e(),_c('el-tab-pane',{attrs:{"label":"Account / Users","name":"account"}},[_c('user-account')],1),(!_vm.isChangePasswordUser)?_c('el-tab-pane',{attrs:{"label":"Gateways","name":"anchors"}},[_c('userAnchors')],1):_vm._e(),(!_vm.isChangePasswordUser)?_c('el-tab-pane',{attrs:{"label":_vm.isTracker
            ? 'Locations & Assets'
            : _vm.isClientDefault
            ? 'Locations'
            : 'Tags',"name":"locations"}},[_c('userBeacons',{attrs:{"batteryIcon":_vm.batteryIcon}})],1):_vm._e(),(
          (_vm.local === 'local' && !_vm.isChangePasswordUser) || !_vm.isClientDefault
        )?_c('el-tab-pane',{attrs:{"label":_vm.isClientDefault ? 'Beacon History' : 'Tag History',"name":"beacon-history"}},[_c('user-beacon-history')],1):_vm._e(),(!_vm.isChangePasswordUser)?_c('el-tab-pane',{attrs:{"label":"Wireless Networks","name":"ssids"}},[_c('user-ssids')],1):_vm._e(),(_vm.isAdmin && !_vm.isChangePasswordUser && _vm.isClientDefault)?_c('el-tab-pane',{attrs:{"label":"Layout","name":"layout"}},[_c('user-layout')],1):_vm._e(),(_vm.isAdmin && !_vm.isChangePasswordUser)?_c('el-tab-pane',{attrs:{"label":"API Key","name":"api-key"}},[_c('user-api-key')],1):_vm._e(),(!_vm.isChangePasswordUser && _vm.isClientDefault)?_c('el-tab-pane',{attrs:{"label":"Documentation","name":"faq"}},[_c('user-faq')],1):_vm._e()],1)],1),_c('el-dialog',{attrs:{"title":"Discard Changes","visible":_vm.unsavedChangesDialog,"close-on-click-modal":false,"close-on-press-escape":false,"width":"500px"},on:{"update:visible":function($event){_vm.unsavedChangesDialog=$event}}},[_c('span',{staticClass:"warning"},[_vm._v("You have unsaved changes! "),_c('br'),_vm._v(" Are you sure you want to discard them? "),_c('br')]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.continueEditing()}}},[_vm._v("Continue Editing")]),_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":_vm.discardChangesFunction}},[_vm._v("Discard Changes")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }