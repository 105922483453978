var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-fullscreen-wrapper"},[_c('div',{staticClass:"container"},[(_vm.anchors.isEmpty)?_c('h4',[_vm._v(" You must have an anchor to see data on this page. ")]):_vm._e()]),(!_vm.isLoading)?[_c('div',{staticClass:"global-fullscreen-wrapper__details__clock"},[_c('i',{staticClass:"el-icon-timer"}),_vm._v(" "+_vm._s(_vm.moment.tz(this.clientData.timezone).format("HH:mm"))+" ")]),_c('div',{staticClass:"global-display"},_vm._l((_vm.anchors.data),function(anchor){return _c('div',{key:anchor.id,staticClass:"container global-fullscreen-wrapper__details"},[(anchor.activeRoute)?[(anchor.activeRoute)?_c('div',{staticClass:"global-fullscreen-wrapper__anchor"},[_c('b',[_vm._v("Label: ")]),_vm._v(" "+_vm._s(anchor.label)+" "),_c('b',[_vm._v("ID: ")]),_vm._v(" "+_vm._s(anchor.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" "),_c('wifi-icon',{attrs:{"signal":anchor.rssi,"noConnection":anchor.lastHeartbeat
                  ? _vm.isAnchorConnected(anchor.lastHeartbeat.createdDate)
                  : false}}),(
                anchor.lastHeartbeat
                  ? _vm.isAnchorConnected(anchor.lastHeartbeat.createdDate)
                  : false
              )?_c('span',{staticClass:"anchor__offline"},[_vm._v(" Offline ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"global-fullscreen-wrapper__options"},[(anchor.activeRoute)?[_c('div',{staticClass:"global-fullscreen-wrapper__options--left"},[_c('p',[_vm._v("Select a tag to follow:")]),_c('el-select',{attrs:{"change":_vm.setData(anchor),"placeholder":"based on tag"},model:{value:(anchor.tagOnTopOrd),callback:function ($$v) {_vm.$set(anchor, "tagOnTopOrd", $$v)},expression:"anchor.tagOnTopOrd"}},_vm._l((anchor.selectData),function(tag){return _c('el-option',{key:`(${tag.ord}) ${tag.label || tag.tagId}`,attrs:{"label":`(${tag.ord}) ${tag.label || tag.tagId}`,"value":[tag.ord || '', anchor.anchor_id]}})}),1)],1),_c('div',{staticClass:"global-fullscreen-wrapper__options--right"},[_c('p',[_vm._v("Number of elements in table:")]),_c('el-input-number',{attrs:{"min":1,"max":Math.min(anchor.activeRoute.tags.length, 15)},on:{"change":function($event){return _vm.setTableData(anchor)}},model:{value:(anchor.numberOfRows),callback:function ($$v) {_vm.$set(anchor, "numberOfRows", $$v)},expression:"anchor.numberOfRows"}})],1)]:_c('h4',[_vm._v(" This anchor doesn't have an active route. Go to the "),_c('router-link',{attrs:{"to":{
                  name: 'routes',
                  params: { anchorId: this.$route.params.anchorId }
                }}},[_vm._v("routes tab")]),_vm._v(" to configure one. ")],1)],2),(anchor.activeRoute)?_c('fullscreen',{ref:"fullscreen",refInFor:true,staticClass:"fullscreen__on",on:{"change":_vm.fullscreenChange}},[(_vm.fullscreen)?_c('div',{staticClass:"fullscreen__close",on:{"click":function($event){return _vm.toggle()}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e(),(anchor.tableData)?[_c('el-table',{ref:"predictions",refInFor:true,class:`table__rows--${Math.min(
                    Math.min(10, anchor.tableData.length),
                    Math.min(anchor.numberOfRows, 10)
                  )}`,staticStyle:{"width":"100%"},attrs:{"highlight-current-row":"","row-class-name":_vm.tableRowClassName,"data":anchor.tableData,"border":""}},[_c('el-table-column',{attrs:{"resizable":false,"label":"Label","min-width":67},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getTagInfo(anchor.selectData, scope.row.ord).label)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"resizable":false,"label":"Time to arrival","min-width":33},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.moment .tz( _vm.moment.unix(scope.row.prediction), _vm.clientData.timezone ) .format("HH:mm"))+" ")]}}],null,true)})],1)]:_vm._e(),(_vm.fullscreen)?[(_vm.anchors.data[1].tableData)?[_c('el-table',{ref:"predictions",refInFor:true,class:`table__rows--${Math.min(
                      Math.min(5, _vm.anchors.data[1].tableData.length),
                      Math.min(_vm.anchors.data[1].numberOfRows, 5)
                    )}`,staticStyle:{"width":"100%"},attrs:{"highlight-current-row":"","row-class-name":_vm.tableRowClassName,"data":_vm.anchors.data[1].tableData,"border":""}},[_c('el-table-column',{attrs:{"resizable":false,"label":"Label"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getTagInfo(_vm.anchors.data[1].selectData, scope.row.ord) .label)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"resizable":false,"label":"Time to arrival"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.moment.unix(scope.row.prediction).format("HH:mm"))+" ")]}}],null,true)})],1)]:_vm._e()]:_vm._e()],2):_vm._e()]:_vm._e()],2)}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }